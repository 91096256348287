import { createSelector, ParametricSelector } from '@reduxjs/toolkit';
import { GROUPS } from '../../constants/groups';
import { RootState } from '../../state';
import { User } from '../../state/user/types';

export const userStateSelector = (state: RootState) => state.user;

export const currentUserSelector = createSelector(userStateSelector, (userState) => userState.currentUser);

export const userLangSelector = createSelector(userStateSelector, (userState) => userState.currentUser.language);

export const userAuthStateSelector = createSelector(userStateSelector, (userState) => {
  const isAuthenticated = !!userState.currentUser.username;
  return {
    isAuthenticated: isAuthenticated,
    groups: isAuthenticated ? userState.currentUser.groups : [],
  };
});

export const checkGroupPermission = (authorizedGroups: string[] | undefined, user: User): boolean => {
  if (typeof authorizedGroups === 'undefined' || !authorizedGroups.length) {
    return true;
  }

  if (user.groups.includes(GROUPS.ADMIN)) {
    if (user.viewAsGroup) {
      return authorizedGroups.includes(user.viewAsGroup);
    }
    return true;
  }

  return user.groups.some((group) => authorizedGroups.includes(group));
};

export const isAuthorizedSelector: ParametricSelector<RootState, string[] | undefined, boolean> = createSelector(
  userStateSelector,
  (state: RootState, authorizedGroups: string[] | undefined) => authorizedGroups,
  (userState, authorizedGroups) => checkGroupPermission(authorizedGroups, userState.currentUser),
);
