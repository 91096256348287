import { createReducer } from '@reduxjs/toolkit';
import {
  getOpenShipmentOrdersAction,
  getOrderAction,
  getOrderItemAction,
  getOrdersAction,
  getPendingOrdersAction,
} from './actions';
import { OrderState } from './types';

export const initialState: OrderState = {
  orders: [],
  ordersTotal: 0,
  order: undefined,
};

const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOrdersAction.fulfilled, (state, action) => {
      state.orders = action.payload?.data;
      state.ordersTotal = action.payload?.metadata?.total || action.payload?.data?.length || 0;
    })
    .addCase(getOrdersAction.rejected, (state) => {
      state.orders = [];
      state.ordersTotal = 0;
    })
    .addCase(getPendingOrdersAction.fulfilled, (state, action) => {
      state.orders = action.payload?.data;
      state.ordersTotal = action.payload?.metadata?.total || action.payload?.data?.length || 0;
    })
    .addCase(getPendingOrdersAction.rejected, (state) => {
      state.orders = [];
      state.ordersTotal = 0;
    })
    .addCase(getOpenShipmentOrdersAction.fulfilled, (state, action) => {
      state.orders = action.payload?.data;
      state.ordersTotal = action.payload?.metadata?.total || action.payload?.data?.length || 0;
    })
    .addCase(getOpenShipmentOrdersAction.rejected, (state) => {
      state.orders = [];
      state.ordersTotal = 0;
    })
    .addCase(getOrderAction.fulfilled, (state, action) => {
      state.order = action.payload;
    })
    .addCase(getOrderAction.rejected, (state) => {
      state.order = undefined;
    })
    .addCase(getOrderItemAction.fulfilled, (state, action) => {
      state.order = action.payload;
    })
    .addCase(getOrderItemAction.rejected, (state) => {
      state.order = undefined;
    });
});

export default orderReducer;
