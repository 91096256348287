import { GROUPS } from '../constants/groups';

export enum ClientRoute {
  HOME = '/',
  PASSWORD_RESET = '/password/reset',
  USERS = '/users',
  ORDERS = '/orders',
  ORDER_DETAILS = '/orders/:reference',
  ORDER_DETAILS_ITEM = '/orders/:reference/:lineNo',
}

export type PathArgs =
  | { path: ClientRoute.HOME }
  | { path: ClientRoute.PASSWORD_RESET }
  | { path: ClientRoute.USERS }
  | { path: ClientRoute.ORDERS }
  | { path: ClientRoute.ORDER_DETAILS; params: { reference: string } }
  | { path: ClientRoute.ORDER_DETAILS_ITEM; params: { reference: string; lineNo: string } };

export const ClientRouteGroups = {
  HOME: [],
  PASSWORD_RESET: [],
  USERS: [GROUPS.MANAGER],
  ORDERS: [GROUPS.MANAGER, GROUPS.INSPECTOR, GROUPS.LOGISTICS],
  ORDER_DETAILS: [GROUPS.MANAGER, GROUPS.INSPECTOR, GROUPS.LOGISTICS],
  ORDER_DETAILS_ITEM: [GROUPS.MANAGER, GROUPS.INSPECTOR, GROUPS.LOGISTICS],
};
