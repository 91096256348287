import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CreateUpdateUserModal from './CreateUpdateUserModal';
import styles from './UserTableToolbar.module.scss';

type Props = {
  onSuccess: () => void;
};

const UserTableToolbar: React.FC<Props> = ({ onSuccess }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <div className={styles.wrapper}>
      <Button startIcon={<Add />} color='success' variant='outlined' onClick={() => setShowCreateModal(true)}>
        <FormattedMessage id='users_actions_create' defaultMessage='Create new User' />
      </Button>
      <CreateUpdateUserModal
        onSuccess={onSuccess}
        show={showCreateModal}
        handleToggleModal={() => setShowCreateModal(!showCreateModal)}
      />
    </div>
  );
};

export default UserTableToolbar;
