import axios, { AxiosRequestConfig } from 'axios';
import { RequestError } from '../state/request/types';

export type RequestConfig = AxiosRequestConfig;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (err) => {
    const customError: RequestError = {
      code: '',
      name: '',
      message: 'An unknown error occurred',
    };

    if (err?.response?.data?.errors) {
      return Promise.reject(err?.response?.data);
    }

    if (typeof err?.response?.data?.error === 'object') {
      const { code, name, message } = err.response.data.error;
      customError.code = code ? code.toString() : '';
      customError.name = name || '';
      customError.message = message || '';
    } else if (typeof err?.response?.data?.error === 'string') {
      const { data, status, statusText } = err.response;
      customError.code = status ? status.toString() : '';
      customError.name = statusText || '';
      customError.message = data.error || '';
    }
    return Promise.reject(customError);
  },
);

export default api;
