import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GROUPS } from '../../../../constants/groups';
import { requestSelector } from '../../../../selectors/request';
import { isAuthorizedSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { approveOrderAction, rejectOrderAction, updateOrderAction } from '../../../../state/orders/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  reference: string;
  status: string;
  orderNo: string;
  lineNo: string;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const STATUS_ACTIONS: Record<string, any> = {
  approve: { action: approveOrderAction, color: 'success' },
  reject: { action: rejectOrderAction, color: 'error' },
};

const ConfirmUpdateAcceptanceStatusModal: React.FC<Props> = ({
  reference,
  status,
  orderNo,
  lineNo,
  handleToggleModal,
  show,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isAdmin = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.ADMIN]));
  const action = STATUS_ACTIONS[status]?.action;
  const updateRequest = useAppSelector((state) => requestSelector(state, action.typePrefix));

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={action.typePrefix} config={{ messageValues: {} }} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='orders_updateAcceptanceStatusModal_title'
            defaultMessage='{action, select, approve {Approve} reject {Reject} other { }} Order "{reference}"?'
            values={{ reference: `${orderNo}${lineNo !== '1' ? ` (${lineNo})` : ''}`, action: status }}
          />
        </DialogTitle>
        {isAdmin && (
          <DialogContent>
            <DialogContentText color='warning.main'>
              <FormattedMessage
                id='orders_updateAcceptanceStatusModal_adminWarningText'
                defaultMessage='This action is usually performed by the vendor. Please make sure you are authorized to perform this action.'
              />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              dispatch(action(reference)).then((response: any) => {
                setShowSnackbar(true);
                if (response?.payload) {
                  handleToggleModal();
                  onSuccess();
                }
              });
            }}
            disabled={updateRequest.status === 'pending'}
            color={STATUS_ACTIONS[status]?.color}>
            <FormattedMessage id={`common_${status}`} defaultMessage={status} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmUpdateAcceptanceStatusModal;
