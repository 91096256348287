export enum RequestActionTypes {
  CLEAR_REQUEST_STACK = 'CLEAR_REQUEST_STACK',
}
export type RequestError = {
  code?: string;
  name?: string;
  message?: string;
};

export type Request = {
  status?: 'pending' | 'fulfilled' | 'rejected';
  error?: RequestError;
  errors?: RequestError[];
  payload?: any;
};

export type RequestState = {
  [key: string]: Request;
};
