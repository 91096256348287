import { configureStore } from '@reduxjs/toolkit';
import orderReducer from './orders/reducer';
import requestReducer from './request/reducer';
import userReducer from './user/reducer';

export const store = configureStore({
  reducer: {
    requests: requestReducer,
    user: userReducer,
    order: orderReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
