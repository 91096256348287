import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { deleteUserAction } from '../../../../state/user/actions';
import { UserObject } from '../../../../state/user/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  user: UserObject;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const DeleteWorkshopServiceModal: React.FC<Props> = ({ user, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={deleteUserAction.typePrefix}
          config={{ messageValues: { username: `${user?.firstName} ${user?.lastName} (${user?.email})` } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='users_deleteModal_title'
            defaultMessage='Delete User "{email}"?'
            values={{ email: user.email }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'medium' }}>
            <FormattedMessage id='users_deleteModal_warningText' defaultMessage='This action cannot be undone.' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteUserAction(user.id)).then((response) => {
                setShowSnackbar(true);
                if (response?.payload) {
                  handleToggleModal();
                  onSuccess();
                }
              });
            }}
            color='error'>
            <FormattedMessage id='common_delete' defaultMessage='Delete' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteWorkshopServiceModal;
