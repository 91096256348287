import { loadLocalStorageState, saveLocalStorageState } from '@chronext/react-common';
import { createReducer } from '@reduxjs/toolkit';
import { getColorMode, getLanguage } from '../../api/user';
import {
  getCurrentUserAction,
  getUsersAction,
  loginAction,
  logoutAction,
  setColorModeAction,
  setLanguageAction,
  setViewAsGroupAction,
} from './actions';
import { UserState } from './types';

export const initialState: UserState = {
  users: [],
  usersTotal: 0,
  currentUser: {
    token: '',
    username: '',
    user: {},
    groups: [],
    language: 'en',
    colorMode: 'dark',
    viewAsGroup: undefined,
  },
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCurrentUserAction.fulfilled, (state, action) => {
      if (action.payload?.token) {
        state.currentUser.token = action.payload?.token;
        state.currentUser.user = action.payload?.user;
        state.currentUser.groups = action.payload?.group ? [action.payload?.group] : [];
        state.currentUser.username = `${action.payload?.user?.firstName} ${action.payload?.user?.lastName}`;
      } else {
        state.currentUser = initialState.currentUser;
      }
      const colorMode = getColorMode();
      const language = getLanguage();
      const viewAsGroup = loadLocalStorageState('user_viewAsGroup');
      state.currentUser.language = language || initialState.currentUser.language;
      state.currentUser.colorMode = colorMode || initialState.currentUser.colorMode;
      state.currentUser.viewAsGroup = viewAsGroup || initialState.currentUser.viewAsGroup;
    })
    .addCase(getCurrentUserAction.rejected, (state) => {
      state.currentUser = initialState.currentUser;
      localStorage.removeItem('user_token');
    })
    .addCase(loginAction.fulfilled, (state, action) => {
      state.currentUser.token = action.payload?.token;
      state.currentUser.user = action.payload?.user;
      state.currentUser.groups = [action.payload?.group];
      state.currentUser.username = `${action.payload?.user?.firstName} ${action.payload?.user?.lastName}`;
      saveLocalStorageState('user_token', action.payload?.token);
    })
    .addCase(getUsersAction.fulfilled, (state, action) => {
      state.users = action.payload?.data;
      state.usersTotal = action.payload?.metadata?.filtered || action.payload?.data?.length || 0;
    })
    .addCase(logoutAction.fulfilled, () => {
      localStorage.removeItem('user_token');
      localStorage.removeItem('user_language');
      localStorage.removeItem('user_colorMode');
    })
    .addCase(setLanguageAction, (state, action) => {
      state.currentUser.language = action.payload;
      saveLocalStorageState('user_language', action.payload);
    })
    .addCase(setColorModeAction, (state, action) => {
      state.currentUser.colorMode = action.payload;
      saveLocalStorageState('user_colorMode', action.payload);
    })
    .addCase(setViewAsGroupAction, (state, action) => {
      if (action.payload === 'Reset') {
        state.currentUser.viewAsGroup = undefined;
        localStorage.removeItem('user_viewAsGroup');
      } else {
        state.currentUser.viewAsGroup = action.payload;
        saveLocalStorageState('user_viewAsGroup', action.payload);
      }
    });
});

export default userReducer;
