import { OrderActions } from '../state/orders/types';
import { UserActions } from '../state/user/types';

type Messages = {
  [key: string]: {
    id: string;
    defaultMessage: string;
  };
};

export const SUCCESS_MESSAGES: Messages = {
  [UserActions.login]: {
    id: 'successMessages_user_login',
    defaultMessage: 'Successfully logged in',
  },
  [UserActions.createUser]: {
    id: 'successMessages_user_createUser',
    defaultMessage: 'Successfully created user {username}',
  },
  [UserActions.updateUser]: {
    id: 'successMessages_user_updateUser',
    defaultMessage: 'Successfully updated user {username}',
  },
  [UserActions.deleteUser]: {
    id: 'successMessages_user_deleteUser',
    defaultMessage: 'Successfully deleted user {username}',
  },
  [UserActions.setNewPassword]: {
    id: 'successMessages_user_setNewPassword',
    defaultMessage: 'Successfully set new password',
  },
  [OrderActions.updateOrder]: {
    id: 'successMessages_order_updateOrder',
    defaultMessage: 'Successfully updated order {reference}',
  },
  [OrderActions.updateOrders]: {
    id: 'successMessages_order_updateOrders',
    defaultMessage: 'Successfully updated {count, plural, one {order} other {orders}} {references}',
  },
  [OrderActions.approveOrder]: {
    id: 'successMessages_order_approveOrder',
    defaultMessage: 'Successfully approved order {reference}',
  },
  [OrderActions.rejectOrder]: {
    id: 'successMessages_order_rejectOrder',
    defaultMessage: 'Successfully rejected order {reference}',
  },
  default: {
    id: 'successMessages_default',
    defaultMessage: 'Successfully executed action',
  },
};
