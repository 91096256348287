import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar as MuiAppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { APP_CONFIG } from '../../../config/app';
import { GROUPS } from '../../../constants/groups';
import { ClientRoute } from '../../../routing';
import { currentUserSelector, userAuthStateSelector } from '../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../state';
import { logoutAction } from '../../../state/user/actions';
import ColorModeSwitcher from './components/ColorModeSwitcher';
import LanguageSwitcher from './components/LanguageSwitcher';
import UserGroupSwitcher from './components/UserGroupSwitcher';

type Props = {
  handleToggle: () => void;
};

const Navbar: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const userAuthState = useAppSelector(userAuthStateSelector);
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logoutAction()).then(() => {
      history.push(ClientRoute.HOME);
      history.go(0);
    });
  };

  return (
    <MuiAppBar position='sticky' elevation={0}>
      <Toolbar variant='dense'>
        <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
          <div>
            <IconButton
              color='inherit'
              edge='start'
              onClick={props.handleToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <LanguageSwitcher />
          </div>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography color='inherit' variant='button'>
              {currentUser.username}
            </Typography>
            {!APP_CONFIG.isProd && currentUser.groups.includes(GROUPS.ADMIN) && <UserGroupSwitcher />}
            <ColorModeSwitcher />
            {userAuthState.isAuthenticated && (
              <IconButton
                size='large'
                aria-label='logout'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleLogout}
                color='inherit'>
                <LogoutIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};
export default Navbar;
