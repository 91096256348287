import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOrder,
  getOrderItem,
  getOrders,
  getOrdersByShippingStatus,
  getOrdersByAcceptanceStatus,
  updateOrder,
  updateOrderAcceptanceStatus,
  UpdateOrderParams,
} from '../../api/order';
import { ACCEPTANCE_STATUS, SHIPPING_STATUS } from '../../constants/orders';
import { OrderActions } from './types';

export const getOrdersAction = createAsyncThunk(OrderActions.getOrders, async (params: URLSearchParams) => {
  const response = await getOrders(params);
  return response.data;
});

export const getPendingOrdersAction = createAsyncThunk(
  OrderActions.getPendingOrders,
  async (params: URLSearchParams) => {
    const response = await getOrdersByAcceptanceStatus(ACCEPTANCE_STATUS.Pending, params);
    return response.data;
  },
);

export const getOpenShipmentOrdersAction = createAsyncThunk(
  OrderActions.getOpenShipmentOrders,
  async (params: URLSearchParams) => {
    const response = await getOrdersByShippingStatus(SHIPPING_STATUS.Pending, params);
    return response.data;
  },
);

export const getOrderAction = createAsyncThunk(OrderActions.getOrder, async (reference: string) => {
  const response = await getOrder(reference);
  return response.data;
});

export type GetOrderParams = {
  reference: string;
  lineNo: string;
};

export const getOrderItemAction = createAsyncThunk(OrderActions.getOrderItem, async (params: GetOrderParams) => {
  const lineNo = `${params.lineNo}0000`;
  const response = await getOrderItem(params.reference, lineNo);
  return response.data;
});

export const updateOrderAction = createAsyncThunk(
  OrderActions.updateOrder,
  async (params: { reference: string; requestBody: UpdateOrderParams }) => {
    const { reference, requestBody } = params;
    const response = await updateOrder(reference, requestBody);
    return response.data;
  },
);

export const updateOrdersAction = createAsyncThunk(
  OrderActions.updateOrders,
  async (params: { references: string[]; requestBody: UpdateOrderParams }) => {
    const { references, requestBody } = params;
    const promises = references.map((reference) => updateOrder(reference, requestBody));
    const responses = await Promise.all(promises);
    return responses.map((response) => response.data);
  },
);

export const approveOrderAction = createAsyncThunk(OrderActions.approveOrder, async (reference: string) => {
  const response = await updateOrderAcceptanceStatus(reference, ACCEPTANCE_STATUS.Approved);
  return response.data;
});

export const rejectOrderAction = createAsyncThunk(OrderActions.rejectOrder, async (reference: string) => {
  const response = await updateOrderAcceptanceStatus(reference, ACCEPTANCE_STATUS.Rejected);
  return response.data;
});
