import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import { AsyncThunk } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { requestSelector } from '../../../selectors/request';
import { userLangSelector } from '../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../state';
import { translateColumnLabels } from '../../../utils/translationUtils';

export type ServerSidePaginatedTableColDef = GridColDef & {
  id?: string;
};

type SearchParams = {
  [key: string]: string;
};

type Props = Omit<DataGridProps, 'rowCount' | 'pageSize' | 'columns'> & {
  fetchDataEffect: AsyncThunk<any, URLSearchParams, any>;
  columns: ServerSidePaginatedTableColDef[];
  searchParams: SearchParams;
  totalCount: number;
  triggerRefresh?: boolean;
  initPageSize?: number;
};

// Query parameters to use in request url
const urlSearchParams = new URLSearchParams();

const ServerSidePaginatedTable: React.FC<Props> = ({
  fetchDataEffect,
  searchParams,
  totalCount,
  triggerRefresh,
  initPageSize,
  columns,
  disableColumnMenu = true,
  ...props
}) => {
  const dispatch = useAppDispatch();

  // request status of the fetchDataEffect, true means loading data
  const requestState = useAppSelector((state) => {
    return requestSelector(state, fetchDataEffect.typePrefix);
  });

  const [formattedColumns, setFormattedColumns] = useState<GridColDef[]>([]);
  const intl = useIntl();
  const userLang = useAppSelector(userLangSelector);

  // Formats the header of the columns
  useEffect(() => {
    setFormattedColumns(translateColumnLabels(columns, intl));
  }, [columns, userLang]);

  // Component state variables
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(initPageSize || 25);

  // Set pagination query parameters
  useEffect(() => {
    urlSearchParams.set('offset', (page * pageSize).toString());
    urlSearchParams.set('limit', pageSize.toString());
  }, [page, pageSize, searchParams]);

  // Set extra search query parameters coming from props
  useEffect(() => {
    for (const [param, val] of Object.entries(searchParams)) {
      if (val) {
        urlSearchParams.set(param, val);
      } else {
        urlSearchParams.delete(param);
      }
    }
    setPage(0);
  }, [searchParams]);

  // On change of page or search parameters, fetch data
  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchParams, triggerRefresh]);

  const fetchData = useCallback(
    debounce(() => {
      if (requestState?.status === 'pending') {
        return;
      }
      dispatch(fetchDataEffect(urlSearchParams));
    }, 300),
    [],
  );

  return (
    <DataGrid
      density='compact'
      hideFooterSelectedRowCount={!props.checkboxSelection}
      getRowId={(row) => row?.id || nanoid()}
      columns={formattedColumns}
      localeText={{
        noRowsLabel: intl.formatMessage({ id: 'dataGrid_noRowsLabel', defaultMessage: 'No Rows' }),
        noResultsOverlayLabel: intl.formatMessage({
          id: 'dataGrid_noResultsOverlayLabel',
          defaultMessage: 'No results found.',
        }),
        errorOverlayDefaultLabel: intl.formatMessage({
          id: 'dataGrid_errorOverlayDefaultLabel',
          defaultMessage: 'An error occurred.',
        }),
      }}
      pagination={true}
      paginationMode='server'
      disableColumnMenu={disableColumnMenu}
      loading={requestState?.status === 'pending'}
      rowCount={totalCount}
      pageSize={pageSize}
      onPageSizeChange={(newPage) => setPageSize(newPage)}
      page={page}
      onPageChange={(newPage) => setPage(newPage)}
      sx={
        props.onRowClick
          ? {
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
            }
          : {}
      } // Remove focus outline and add cursor pointer if onRowClick is defined
      {...props}
    />
  );
};

export default ServerSidePaginatedTable;
