import { createSelector, ParametricSelector } from '@reduxjs/toolkit';
import { RootState } from '../../state';
import { Request } from '../../state/request/types';

export const requestStateSelector = (state: RootState) => state.requests;

export const requestSelector: ParametricSelector<RootState, string, Request> = createSelector(
  requestStateSelector,
  (state: RootState, actionName: string) => actionName,
  (requests, actionName) => requests[actionName] || {},
);
