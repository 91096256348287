import { RequestQuote, VerifiedUser } from '@mui/icons-material';
import { Box, Divider, Drawer, List, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ClientRoute } from '../../../routing';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { userAuthStateSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import Logo from './components/Logo';
import SidebarItem from './components/SidebarItem';
import styles from './Sidebar.module.scss';

export type Item = {
  name: string | React.ReactNode;
  icon?: React.ReactNode;
  href: string;
  description?: string | React.ReactNode;
  groups: string[];
  cardColor?: string;
  categoryName?: React.ReactNode;
};

export type MenuItem = {
  item: Item;
  children?: Item[];
};

export const MenuItems: MenuItem[] = [
  {
    item: {
      name: <FormattedMessage id='menu_users' defaultMessage='Users' />,
      icon: <VerifiedUser />,
      href: ClientRoute.USERS,
      groups: ClientRouteGroups.USERS,
      description: <FormattedMessage id='menu_users_description' defaultMessage='List available users' />,
      cardColor: 'redDark',
    },
  },
  {
    item: {
      name: <FormattedMessage id='menu_orders' defaultMessage='Orders' />,
      icon: <RequestQuote />,
      href: ClientRoute.ORDERS,
      groups: ClientRouteGroups.ORDERS,
      description: <FormattedMessage id='menu_orders_description' defaultMessage='List available orders' />,
      cardColor: 'greenDark',
    },
  },
];

type Props = {
  open: boolean;
  drawerWidth: number;
  onCloseCallback: () => void;
};

const Sidebar: React.FC<Props> = (props) => {
  const theme = useTheme();
  const userAuthState = useAppSelector(userAuthStateSelector);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const drawerProps = {
    sx: {
      display: {
        xs: isSmUp ? 'none' : 'block',
        sm: isSmUp ? 'block' : 'none',
      },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: props.drawerWidth,
      },
    },
    variant: isSmUp ? 'permanent' : 'temporary',
    open: props.open,
    onClose: props.onCloseCallback,
    ModalProps: {
      keepMounted: true,
    },
  } as const;

  return (
    <Box component='nav' sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer {...drawerProps}>
        <Box
          height='48px'
          alignItems='center'
          display='flex'
          sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'secondary.main' : 'primary.main' }}>
          <Logo />
        </Box>
        <Divider />
        {userAuthState.isAuthenticated ? (
          <List disablePadding className={styles.sidebarList}>
            {MenuItems.map((itemData) => (
              <SidebarItem key={nanoid()} {...itemData} />
            ))}
          </List>
        ) : (
          <Box m={1}>
            <Typography variant='h6'>
              <Skeleton variant='text' />
            </Typography>
            <Typography variant='h6'>
              <Skeleton variant='text' />
            </Typography>
            <Typography variant='h6'>
              <Skeleton variant='text' />
            </Typography>
            <Typography variant='h6'>
              <Skeleton variant='text' />
            </Typography>
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
