import { getPriceLocaleString } from '@chronext/react-common';
import { GridRenderCellParams, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ClientRoute, createPath } from '../../../routing';
import { currentUserSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import { getOrdersAction } from '../../../state/orders/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import StatusBadge from '../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import { generateOrderDetailsPath, ORDERS_TABLE_COLUMNS } from './components/ordersTableHelper';
import OrdersTableToolbar from './components/OrdersTableToolbar';
import styles from './Orders.module.scss';

const ordersTableColumns = (language: string): ServerSidePaginatedTableColDef[] => [
  ...ORDERS_TABLE_COLUMNS,
  {
    id: 'orders_labels_customerPrice',
    headerName: 'List Price',
    field: 'customerPrice',
    renderCell: (params: GridRenderCellParams) =>
      getPriceLocaleString(parseInt(params.row.customerPrice, 10) * 100, `${language}-DE`, 'EUR'),
    filterable: false,
  },
  {
    id: 'orders_labels_orderDate',
    headerName: 'Order Date',
    field: 'orderDate',
    renderCell: (params: GridRenderCellParams) => formatUTCToLocal(params.row.orderDate?.date),
    filterable: false,
  },
  {
    id: 'orders_labels_acceptanceStatus',
    headerName: 'Acceptance Status',
    field: 'acceptanceStatus',
    renderCell: (params: GridRenderCellParams) => (
      <StatusBadge status={params.row.acceptanceStatus ? params.row.acceptanceStatus.toLowerCase() : 'pending'} />
    ),
    minWidth: 160,
    sortable: false,
    filterable: false,
  },
  {
    id: 'orders_labels_shipmentStatus',
    headerName: 'Shipment Status',
    field: 'shipmentStatus',
    renderCell: (params) => <StatusBadge status={params.row.shipmentStatus.toLowerCase()} />,
    minWidth: 160,
    sortable: false,
    filterable: false,
  },
  {
    id: 'orders_labels_vendorName',
    headerName: 'Vendor Name',
    field: 'vendorName',
    valueGetter: (params) => params.row.vendor.name,
    sortable: false,
    filterable: false,
  },
];

const OrdersOverviewTable: React.FC = () => {
  const orders = useAppSelector((store) => store.order.orders);
  const totalCount = useAppSelector((store) => store.order.ordersTotal);
  const currentUser = useAppSelector(currentUserSelector);
  const { language } = currentUser;
  const orderColumns = useMemo(() => ordersTableColumns(language), [language]);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(orderColumns);
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filterVendor, setFilterVendor] = useState<string>('all');
  const history = useHistory();

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vendor = event.target.value;
    setFilterVendor(vendor);
    setSearchParams({ ...searchParams, vendorKey: vendor === 'all' ? undefined : vendor });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSearchParams({
      ...searchParams,
      s: sortModel[0]?.field,
      d: sortModel[0]?.sort,
    });
  }, []);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      filter: searchText,
    });
  }, [searchText]);

  useEffect(() => {
    if (currentUser.user?.vendors.length === 1) {
      const cols = orderColumns.filter((column) => column.field !== 'vendorName');
      setTableColumns([...cols]);
    }
  }, [currentUser.user?.vendors.length]);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getOrdersAction.typePrefix} />
      <ServerSidePaginatedTable
        rows={orders}
        columns={tableColumns}
        totalCount={totalCount}
        fetchDataEffect={getOrdersAction}
        initPageSize={50}
        searchParams={searchParams}
        getRowHeight={() => 'auto'}
        onRowClick={(params: GridRowParams) => {
          const path = generateOrderDetailsPath(params);
          history.push(path);
        }}
        components={{
          Toolbar: OrdersTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            searchText,
            onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
            filterVendor,
            handleFilterChange,
          },
        }}
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
      />
    </div>
  );
};

export default OrdersOverviewTable;
