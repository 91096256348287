import { DeleteForever, Edit } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { currentUserSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import { getUsersAction } from '../../../state/user/actions';
import { UserObject } from '../../../state/user/types';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import CreateUpdateUserModal from './components/CreateUpdateUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import UserTableToolbar from './components/UserTableToolbar';
import styles from './Users.module.scss';

const usersTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'users_labels_email',
    headerName: 'Email',
    field: 'email',
    flex: 1,
  },
  {
    id: 'users_labels_firstName',
    headerName: 'First Name',
    field: 'firstName',
    flex: 1,
  },
  {
    id: 'users_labels_lastName',
    headerName: 'Last Name',
    field: 'lastName',
    flex: 1,
  },
  {
    id: 'users_labels_role',
    headerName: 'Role',
    field: 'role',
    flex: 1,
    sortable: false,
    valueGetter: (params) => params.row?.customerGroup?.obj?.name,
  },
  {
    id: 'users_labels_vendors',
    headerName: 'Vendors',
    field: 'vendors',
    flex: 1,
    sortable: false,
    valueGetter: (params) => params.row?.custom?.fields?.mc_vendors,
  },
];

const Users: React.FC = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const intl = useIntl();
  const users = useAppSelector((store) => store.user.users);
  const totalCount = useAppSelector((store) => store.user.usersTotal);
  const [searchParams, setSearchParams] = useState({});
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(usersTableColumns);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserObject | null>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const actionsColumn: ServerSidePaginatedTableColDef = {
    id: 'common_actions',
    headerName: 'Actions',
    field: 'actions',
    filterable: false,
    sortable: false,
    width: 100,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <ButtonGroup variant='text' fullWidth sx={{ alignItems: 'left' }}>
          <ButtonGroupIconButton
            color='info'
            onClick={() => {
              setSelectedUser(params.row);
              setShowUpdateModal(true);
            }}
            title={intl.formatMessage({
              id: 'common_edit',
              defaultMessage: 'Edit',
            })}>
            <Edit />
          </ButtonGroupIconButton>
          {currentUser.user?.email !== params.row.email && (
            <ButtonGroupIconButton
              color='error'
              onClick={() => {
                setSelectedUser(params.row);
                setShowDeleteConfirmationModal(true);
              }}
              title={intl.formatMessage({
                id: 'common_delete',
                defaultMessage: 'Delete',
              })}>
              <DeleteForever />
            </ButtonGroupIconButton>
          )}
        </ButtonGroup>
      );
    },
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSearchParams({
      ...searchParams,
      s: sortModel[0]?.field,
      d: sortModel[0]?.sort,
    });
  }, []);

  useEffect(() => {
    setTableColumns([...usersTableColumns, actionsColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getUsersAction.typePrefix} />
      {showUpdateModal && selectedUser && (
        <CreateUpdateUserModal
          show={showUpdateModal}
          user={selectedUser}
          handleToggleModal={() => setShowUpdateModal(!showUpdateModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showDeleteConfirmationModal && selectedUser && (
        <DeleteUserModal
          show={showDeleteConfirmationModal}
          user={selectedUser}
          handleToggleModal={() => setShowDeleteConfirmationModal(!showDeleteConfirmationModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      <ServerSidePaginatedTable
        rows={users}
        columns={tableColumns}
        totalCount={totalCount}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getUsersAction}
        searchParams={searchParams}
        components={{
          Toolbar: UserTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
          },
        }}
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
      />
    </div>
  );
};

export default Users;
