import { Stack } from '@mui/material';
import { GridRenderCellParams, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { STATUS_ACTION_MAP } from '../../../constants/orders';
import { ClientRoute, createPath } from '../../../routing';
import { useAppSelector } from '../../../state';
import { getPendingOrdersAction } from '../../../state/orders/actions';
import { Order } from '../../../state/orders/types';
import { formatUTCToLocal } from '../../../utils/datetime';
import StatusBadge from '../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import ConfirmUpdateAcceptanceStatusModal from './components/ConfirmUpdateAcceptanceStatusModal';
import { generateOrderDetailsPath, ORDERS_TABLE_COLUMNS } from './components/ordersTableHelper';
import OrdersTableToolbar from './components/OrdersTableToolbar';
import styles from './Orders.module.scss';

const ordersTableColumns: ServerSidePaginatedTableColDef[] = [
  ...ORDERS_TABLE_COLUMNS,
  {
    id: 'orders_labels_orderDate',
    headerName: 'Order Date',
    field: 'orderDate',
    renderCell: (params: GridRenderCellParams) => formatUTCToLocal(params.row.orderDate?.date),
    filterable: false,
  },
];

const OrdersAcceptanceTable: React.FC = () => {
  const orders = useAppSelector((store) => store.order.orders);
  const totalCount = useAppSelector((store) => store.order.ordersTotal);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(ordersTableColumns);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filterVendor, setFilterVendor] = useState<string>('all');
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedOrderAcceptanceStatus, setSelectedOrderAcceptanceStatus] = useState<string>('');
  const [showAcceptanceStatusModal, setShowAcceptanceStatusModal] = useState(false);
  const history = useHistory();

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vendor = event.target.value;
    setFilterVendor(vendor);
    setSearchParams({ ...searchParams, vendorKey: vendor === 'all' ? undefined : vendor });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSearchParams({
      ...searchParams,
      s: sortModel[0]?.field,
      d: sortModel[0]?.sort,
    });
  }, []);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      filter: searchText,
    });
  }, [searchText]);

  const acceptanceStatusColumn: ServerSidePaginatedTableColDef = {
    id: 'orders_labels_acceptanceStatus',
    headerName: 'Acceptance Status',
    field: 'acceptanceStatus',
    align: 'center',
    sortable: false,
    minWidth: 200,
    renderCell: (params: any) => (
      <Stack spacing={1} alignItems='left' justifyContent='center' my={1}>
        {params.row.acceptanceStatus && <StatusBadge status={params.row.acceptanceStatus.toLowerCase()} />}
        {!params.row.acceptanceStatus &&
          Object.entries(STATUS_ACTION_MAP)?.map(([, action]) => (
            <StatusBadge
              key={nanoid()}
              status={action}
              showIcon
              onClick={(ev) => {
                ev?.stopPropagation();
                ev?.preventDefault();
                setSelectedOrder(params.row);
                setSelectedOrderAcceptanceStatus(action);
                setShowAcceptanceStatusModal(true);
              }}
            />
          ))}
      </Stack>
    ),
  };

  useEffect(() => {
    setTableColumns([...ordersTableColumns, acceptanceStatusColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getPendingOrdersAction.typePrefix} />
      {showAcceptanceStatusModal && selectedOrder && (
        <ConfirmUpdateAcceptanceStatusModal
          show={showAcceptanceStatusModal}
          status={selectedOrderAcceptanceStatus}
          reference={selectedOrder.id}
          orderNo={selectedOrder.orderNo}
          lineNo={selectedOrder.lineNo.toString().replace(/0+$/, '')}
          handleToggleModal={() => setShowAcceptanceStatusModal(!showAcceptanceStatusModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      <ServerSidePaginatedTable
        rows={orders}
        columns={tableColumns}
        totalCount={totalCount}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getPendingOrdersAction}
        initPageSize={50}
        searchParams={searchParams}
        getRowHeight={() => 'auto'}
        onRowClick={(params: GridRowParams) => {
          const path = generateOrderDetailsPath(params);
          history.push(path);
        }}
        components={{
          Toolbar: OrdersTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            searchText,
            onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
            filterVendor,
            handleFilterChange,
          },
        }}
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
      />
    </div>
  );
};

export default OrdersAcceptanceTable;
