import { Alert, AlertTitle, Stack } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, RouteProps } from 'react-router-dom';
import { isAuthorizedSelector, userAuthStateSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import Login from './Login';

type Props = RouteProps & {
  groups?: string[];
  needsAuth?: boolean;
};

const PrivateRoute: React.FC<Props> = ({ needsAuth = true, ...props }) => {
  const userAuthState = useAppSelector(userAuthStateSelector);
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, props.groups));

  // Show login page if user is not authenticated and route needs authentication
  if (needsAuth && !userAuthState.isAuthenticated) {
    return <Route {...props} component={Login} />;
  }

  // Checks the authorization
  if (!isAuthorized) {
    return (
      <Route
        {...props}
        component={() => (
          <Stack height='100%' justifyContent='center' alignItems='center'>
            <Alert severity='error' variant='outlined'>
              <AlertTitle>
                <FormattedMessage id='auth_unauthorized_title' defaultMessage='Error' />
              </AlertTitle>
              <FormattedMessage
                id='auth_unauthorized_message'
                defaultMessage='You are not authorized to view this page. If you think this is a mistake, please contact your administrator.'
              />
            </Alert>
          </Stack>
        )}
      />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
