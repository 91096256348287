import { ACCEPTANCE_STATUS, SHIPPING_STATUS } from '../constants/orders';
import { APIRoute, createPath } from '../routing';
import { get, put } from '../utils/httpUtils';

export const getOrders = (params: URLSearchParams) => {
  return get(APIRoute.ORDER, params);
};

export const getOrdersByAcceptanceStatus = (status: ACCEPTANCE_STATUS, params: URLSearchParams) => {
  const url = createPath({
    path: APIRoute.ORDER_ACCEPTANCE,
    params: { status },
  });
  return get(url, params);
};

export const getOrdersByShippingStatus = (status: SHIPPING_STATUS, params: URLSearchParams) => {
  const url = createPath({
    path: APIRoute.ORDER_SHIPPING,
    params: { status },
  });
  return get(url, params);
};

export const getOrder = (reference: string) => {
  const url = createPath({
    path: APIRoute.ORDER_DETAILS,
    params: { reference },
  });
  return get(url);
};

export const getOrderItem = (reference: string, lineNo: string) => {
  const url = createPath({
    path: APIRoute.ORDER_DETAILS_ITEM,
    params: { reference, lineNo },
  });
  return get(url);
};

export type UpdateOrderParams = {
  vendorCarrier?: string;
  vendorTrackingNo?: string;
  vendorComment?: string;
};

export const updateOrder = (reference: string, requestBody: UpdateOrderParams) => {
  const url = createPath({
    path: APIRoute.ORDER_DETAILS,
    params: { reference },
  });
  return put(url, { ...requestBody });
};

export const updateOrderAcceptanceStatus = (reference: string, acceptanceStatus: ACCEPTANCE_STATUS) => {
  const url = createPath({
    path: APIRoute.ORDER_STATUS,
    params: { reference },
  });
  return put(url, { acceptanceStatus });
};
