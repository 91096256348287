import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GROUPS } from '../../../constants/groups';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { isAuthorizedSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import OrdersAcceptanceTable from './OrdersAcceptanceTable';
import OrdersOverviewTable from './OrdersOverviewTable';
import OrdersShippingTable from './OrdersShippingTable';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const Orders: React.FC = () => {
  const canViewShippingTab = useAppSelector((state) =>
    isAuthorizedSelector(
      state,
      ClientRouteGroups.ORDER_DETAILS.filter((g) => g !== GROUPS.INSPECTOR),
    ),
  );
  const canViewPendingOrdersTab = useAppSelector((state) =>
    isAuthorizedSelector(
      state,
      ClientRouteGroups.ORDER_DETAILS.filter((g) => g !== GROUPS.LOGISTICS),
    ),
  );

  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <TabContext value={tabIndex.toString()}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
            <Tab label={<FormattedMessage id='orders_tabs_overview' defaultMessage='Overview' />} />
            {canViewPendingOrdersTab && (
              <Tab label={<FormattedMessage id='orders_tabs_pendingOrders' defaultMessage='Pending Orders' />} />
            )}
            {canViewShippingTab && (
              <Tab label={<FormattedMessage id='orders_tabs_shipping' defaultMessage='Shipping' />} />
            )}
          </Tabs>
        </Box>
        <TabPanel value='0' {...tabProps}>
          <OrdersOverviewTable />
        </TabPanel>
        {canViewPendingOrdersTab && (
          <TabPanel value='1' {...tabProps}>
            <OrdersAcceptanceTable />
          </TabPanel>
        )}
        {canViewShippingTab && (
          <TabPanel value={canViewPendingOrdersTab ? '2' : '1'} {...tabProps}>
            <OrdersShippingTable />
          </TabPanel>
        )}
      </Stack>
    </TabContext>
  );
};

export default Orders;
