import { Clear, LocalShipping, Search } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import styles from './OrdersTableToolbar.module.scss';

type Props = {
  clearSearch: () => void;
  onSearchChange: () => void;
  searchText: string;
  filterVendor: string;
  handleFilterChange: () => void;
  showEditTracking: boolean;
  handleEditTracking: () => void;
};

const OrdersTableToolbar: React.FC<Props> = ({
  searchText,
  onSearchChange,
  clearSearch,
  filterVendor,
  handleFilterChange,
  showEditTracking,
  handleEditTracking,
}) => {
  const currentUser = useAppSelector(currentUserSelector);
  const intl = useIntl();
  const vendors = currentUser.user?.vendors || [];

  return (
    <div className={styles.wrapper}>
      <Stack spacing={1} direction='row'>
        <GridToolbarColumnsButton />
        {vendors.length > 1 && (
          <TextField
            margin='dense'
            size='small'
            value={filterVendor}
            onChange={handleFilterChange}
            label={<FormattedMessage id='orders_labels_vendors' defaultMessage='Vendors' />}
            select
            sx={{ width: '150px' }}>
            <MenuItem value='all'>
              <FormattedMessage id='common_all' defaultMessage='All' />
            </MenuItem>
            {vendors.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
      {showEditTracking && (
        <Button startIcon={<LocalShipping />} color='primary' variant='outlined' onClick={handleEditTracking}>
          <FormattedMessage id='orders_details_updateTracking' defaultMessage='Update Tracking' />
        </Button>
      )}
      <TextField
        sx={{ minWidth: '300px' }}
        variant='standard'
        value={searchText}
        onChange={onSearchChange}
        placeholder={`${intl.formatMessage({
          id: 'common_search',
          defaultMessage: 'Search',
        })}…`}
        InputProps={{
          startAdornment: <Search fontSize='small' />,
          endAdornment: (
            <IconButton
              title={intl.formatMessage({ id: 'common_clear', defaultMessage: 'Clear' })}
              aria-label='Clear'
              size='small'
              style={{ visibility: searchText ? 'visible' : 'hidden' }}
              onClick={clearSearch}>
              <Clear fontSize='small' />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default OrdersTableToolbar;
