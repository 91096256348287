import { PaletteMode } from '@mui/material';

export enum UserActions {
  getCurrentUser = 'user/getCurrentUser',
  checkUserToken = 'user/checkUserToken',
  setLanguage = 'user/setLanguage',
  setColorMode = 'user/setColorMode',
  setViewAsGroup = 'user/setViewAsGroup',
  login = 'user/login',
  logout = 'user/logout',
  getUsers = 'user/getUsers',
  requestPasswordReset = 'user/requestPasswordReset',
  setNewPassword = 'user/setNewPassword',
  createUser = 'user/createUser',
  updateUser = 'user/updateUser',
  deleteUser = 'user/deleteUser',
}

type Vendor = {
  key: string;
  name: string;
};

export type UserObject = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  vendors: Vendor[];
  customerGroup?: Record<string, any>;
  custom?: Record<string, any>;
};

export type User = {
  username: string;
  groups: string[];
  user?: UserObject | Record<string, never>;
  token: string;
  language: string;
  colorMode: PaletteMode;
  viewAsGroup?: string;
};

export type UserState = {
  currentUser: User;
  users: Record<string, any>[];
  usersTotal: number;
};
