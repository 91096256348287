import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { SUCCESS_MESSAGES } from '../../../constants/successMessages';
import { requestSelector } from '../../../selectors/request';
import { useAppSelector } from '../../../state';
import { OrderActions } from '../../../state/orders/types';
import { UserActions } from '../../../state/user/types';

type Config = {
  messageValues?: Record<string, any>;
  [key: string]: any;
};

type Props = {
  actionName: string;
  autoHideDuration?: number;
  config?: Config;
};

const getSuccessMessage = (actionName: string, payload?: any, config?: Config): string | React.ReactElement => {
  const id = SUCCESS_MESSAGES?.[actionName]?.id || SUCCESS_MESSAGES.default.id;
  const defaultMessage = SUCCESS_MESSAGES?.[actionName]?.defaultMessage || SUCCESS_MESSAGES.default.defaultMessage;
  switch (actionName) {
    case UserActions.login:
    case UserActions.deleteUser:
    case UserActions.setNewPassword:
      return <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ ...config?.messageValues }} />;
    case UserActions.createUser:
    case UserActions.updateUser:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{
            ...config?.messageValues,
            username: `${payload?.user?.firstName} ${payload?.user?.lastName} (${payload?.user?.email})`,
          }}
        />
      );
    case OrderActions.updateOrder:
    case OrderActions.approveOrder:
    case OrderActions.rejectOrder:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{ ...config?.messageValues, reference: payload?.order?.orderNo || '' }}
        />
      );
    case OrderActions.updateOrders:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{
            ...config?.messageValues,
            count: payload.length,
            references: payload?.map((res: any) => res?.order?.orderNo).join(', '),
          }}
        />
      );
    default:
      return '';
  }
};

const ActionStatusSnackbar: React.FC<Props> = ({ actionName, autoHideDuration, config }) => {
  const { enqueueSnackbar } = useSnackbar();
  const request = useAppSelector((state) => requestSelector(state, actionName));

  useEffect(() => {
    let message: string | React.ReactElement = '';
    const snackbarOptions = { autoHideDuration: autoHideDuration || 6000 };
    switch (request?.status) {
      case 'rejected':
        if (request?.errors?.length) {
          request?.errors.forEach((error) => {
            message = error?.message || '';
            if (message) {
              enqueueSnackbar(message, { ...snackbarOptions, variant: 'error' });
            }
          });
        } else {
          message = request?.error?.message || '';
          if (message) {
            enqueueSnackbar(message, { ...snackbarOptions, variant: 'error' });
          }
        }
        break;
      case 'fulfilled':
        // remove once errors are consistent
        if (request?.payload?.error) {
          const error = request?.payload?.error;
          enqueueSnackbar(error, { ...snackbarOptions, variant: 'error' });
        } else {
          message = getSuccessMessage(actionName, request?.payload, config);
          if (message) {
            enqueueSnackbar(message, { ...snackbarOptions, variant: 'success' });
          }
        }
        break;
    }
  }, [request]);

  return null;
};

export default ActionStatusSnackbar;
