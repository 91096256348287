export enum ACCEPTANCE_STATUS {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
}

export const STATUS_ACTION_MAP = {
  [ACCEPTANCE_STATUS.Approved]: 'approve',
  [ACCEPTANCE_STATUS.Rejected]: 'reject',
};

export enum SHIPPING_STATUS {
  Pending = 'pending',
  Shipped = 'shipped',
}
