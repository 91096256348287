const deMessages = {
  auth_buttons_login: 'Einloggen',
  auth_buttons_passwordReset: 'Passwort zurücksetzen',
  auth_buttons_setPassword: 'Passwort festlegen',
  auth_labels_email: 'E-Mail-Adresse',
  auth_labels_newPassword: 'Neues Passwort',
  auth_labels_password: 'Passwort',
  auth_labels_repeatPassword: 'Passwort wiederholen',
  auth_login_title: 'Anmeldung',
  auth_passwordReset_successMessage:
    'Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passwortes geschickt, sofern Ihre E-Mail-Adresse in unserem System gespeichert ist. Bitte klicken Sie auf den Link, um Ihr Passwort zurückzusetzen.',
  auth_passwordReset_title: 'Neues Passwort festlegen',
  auth_unauthorized_message:
    'Sie sind nicht berechtigt, diese Seite zu sehen. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an Ihren Administrator.',
  auth_unauthorized_title: 'Fehler',
  common_actions: 'Aktionen',
  common_add: 'Hinzufügen',
  common_all: 'Alle',
  common_approve: 'Genehmigen',
  common_back: 'Zurück',
  common_cancel: 'Abbrechen',
  common_clear: 'Leeren',
  common_close: 'Schließen',
  common_days: '{count, plural, one {# Tag} other {# Tage}}',
  common_decline: 'Ablehnen',
  common_delete: 'Löschen',
  common_details: 'Details',
  common_disable: 'Deaktivieren',
  common_edit: 'Bearbeiten',
  common_enable: 'Aktivieren',
  common_filters: 'Filter',
  common_months: '{count, plural, one {# Monat} other {# Monate}}',
  common_next: 'Weiter',
  common_no: 'Nein',
  common_ok: 'Ok',
  common_optional: 'optional',
  common_refresh: 'Aktualisieren',
  common_reject: 'Ablehnen',
  common_remove: 'Entfernen',
  common_save: 'Speichern',
  common_search: 'Suchen',
  common_set: 'Setzen',
  common_show: 'Anzeigen',
  common_status: 'Status',
  common_submit: 'Übermitteln',
  common_weeks: '{count, plural, one {# Woche} other {# Wochen}}',
  common_years: '{count, plural, one {# Jahr} other {# Jahre}}',
  common_yes: 'Ja',
  dataGrid_errorOverlayDefaultLabel: 'Ein Fehler ist aufgetreten.',
  dataGrid_noResultsOverlayLabel: 'Keine Ergebnisse gefunden.',
  dataGrid_noRowsLabel: 'Keine Zeilen',
  forms_dateFilter_endDate: 'Enddatum',
  forms_dateFilter_startDate: 'Startdatum',
  forms_fileUpload_label: 'Dateien auswählen',
  forms_fileUpload_noFiles: 'Keine Dateien ausgewählt',
  menu_orders: 'Bestellungen',
  menu_orders_description: 'Liste der verfügbaren Bestellungen',
  menu_users: 'Benutzer',
  menu_users_description: 'Liste der verfügbaren Benutzer',
  orders_details_cardTitles_acceptance: 'Genehmigung',
  orders_details_cardTitles_item: 'Artikel',
  orders_details_cardTitles_other: 'Weiteres',
  orders_details_cardTitles_shipment: 'Versand',
  orders_details_title: 'Bestellung {reference}',
  orders_details_updateTracking: 'Sendungsverfolgung aktualisieren',
  orders_labels_acceptanceStatus: 'Genehmigungs-Status',
  orders_labels_brand: 'Marke',
  orders_labels_customerPrice: 'Listenpreis',
  orders_labels_firstAcceptanceDate: 'Genehmigungs-Status erstmals geändert am',
  orders_labels_firstAcceptanceUser: 'Genehmigungs-Status erstmals geändert von',
  orders_labels_image: 'Bild',
  orders_labels_model: 'Modell',
  orders_labels_orderDate: 'Bestelldatum',
  orders_labels_orderNo: 'Bestellnr.',
  orders_labels_orderStatus: 'Status der Bestellung',
  orders_labels_payoutAmount: 'Auszahlungsbetrag',
  orders_labels_reference: 'Referenz',
  orders_labels_shipmentStatus: 'Versandstatus',
  orders_labels_tracking: 'Sendungsverfolgung',
  orders_labels_vendorCarrier: 'Spediteur',
  orders_labels_vendorComment: 'Kommentar',
  orders_labels_vendorItemId: 'Artikel-ID',
  orders_labels_vendorName: 'Name des Verkäufers',
  orders_labels_vendorOrderTrackingDate: 'Tracking zuletzt aktualisiert am',
  orders_labels_vendorOrderTrackingUser: 'Tracking zuletzt aktualisiert von',
  orders_labels_vendorPrice: 'Händlerpreis',
  orders_labels_vendors: 'Verkäufer',
  orders_labels_vendorTrackingNo: 'Sendungsverfolgungsnr.',
  orders_tabs_overview: 'Übersicht',
  orders_tabs_pendingOrders: 'Ausstehende Bestellungen',
  orders_tabs_shipping: 'Versand',
  orders_updateAcceptanceStatusModal_adminWarningText:
    'Diese Aktion wird normalerweise vom Verkäufer durchgeführt. Vergewissern Sie sich, dass Sie zur Durchführung dieser Aktion berechtigt sind.',
  orders_updateAcceptanceStatusModal_title:
    'Bestellung "{reference}" {action, select, approve {genehmigen} reject {ablehnen} other { }}?',
  orders_updateModal_title: 'Ausgewählte {count, plural, one {Bestellung} other {Bestellungen}} aktualisieren?',
  status_accepted: 'angenommen',
  status_approved: 'genehmigt',
  status_pending: 'ausstehend',
  status_refused: 'abgewiesen',
  status_rejected: 'abgelehnt',
  status_requested: 'beantragt',
  status_shipped: 'versendet',
  successMessages_order_approveOrder: 'Bestellung {reference} erfolgreich genehmigt',
  successMessages_order_rejectOrder: 'Bestellung {reference} erfolgreich abgelehnt',
  successMessages_order_updateOrder: 'Bestellung {reference} erfolgreich aktualisiert',
  successMessages_order_updateOrders:
    '{count, plural, one {Bestellung} other {Bestellungen}} {references} aktualisiert',
  successMessages_user_createUser: 'Benutzer {username} erfolgreich erstellt',
  successMessages_user_deleteUser: 'Benutzer {username} erfolgreich gelöscht',
  successMessages_user_login: 'Erfolgreich angemeldet',
  successMessages_user_setNewPassword: 'Neues Passwort erfolgreich festgelegt',
  successMessages_user_updateUser: 'Benutzer {username} erfolgreich aktualisiert',
  users_actions_create: 'Neuen Benutzer anlegen',
  users_createModal_description:
    'Um einen neuen Benutzer hinzuzufügen, füllen Sie bitte die unten stehenden Felder aus.',
  users_createModal_title: 'Neuer Benutzer',
  users_deleteModal_title: 'Benutzer "{email}" löschen?',
  users_deleteModal_warningText: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  users_labels_email: 'E-Mail-Adresse',
  users_labels_firstName: 'Vorname',
  users_labels_lastName: 'Nachname',
  users_labels_role: 'Rolle',
  users_labels_vendors: 'Vendors',
  users_updateModal_description:
    'Um diesen Benutzer zu aktualisieren, ändern Sie bitte die unten stehenden Werte wie erforderlich. Bitte beachten Sie, dass die E-Mail nicht geändert werden kann.',
  users_updateModal_title: 'Benutzer "{email}" aktualisieren',
};

export default deMessages;
