import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GROUPS } from '../../../../constants/groups';
import { requestSelector } from '../../../../selectors/request';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { createUserAction, updateUserAction } from '../../../../state/user/actions';
import { User, UserObject } from '../../../../state/user/types';
import { isValidEmail } from '../../../../utils/textUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  user?: UserObject;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: {
  email: string;
  firstName: string;
  lastName: string;
  group: string;
  vendors: string[];
} = {
  email: '',
  firstName: '',
  lastName: '',
  group: '',
  vendors: [],
};

const CreateUpdateUserModal: React.FC<Props> = ({ user, onSuccess, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const updateRequest = useAppSelector((state) => requestSelector(state, updateUserAction.typePrefix));
  const createRequest = useAppSelector((state) => requestSelector(state, createUserAction.typePrefix));
  const currentUser = useAppSelector(currentUserSelector);
  const vendors = currentUser.user?.vendors || [];

  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleGroupChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, group: event.target.value });
  };

  const handleVendorsChange = (event: SelectChangeEvent<string[]>) => {
    let newVendors: string[] = [];
    if (Array.isArray(event.target.value)) {
      newVendors = event.target.value.sort();
    }
    setFormData({ ...formData, vendors: newVendors });
  };

  const submitForm = () => {
    if (user) {
      dispatch(
        updateUserAction({
          id: user.id,
          requestBody: { ...formData },
        }),
      ).then((response) => {
        setShowUpdateSnackbar(true);
        if (response?.payload) {
          clearForm();
          handleToggleModal();
          onSuccess();
        }
      });
    } else {
      dispatch(createUserAction(formData)).then((response) => {
        setShowCreateSnackbar(true);
        if (response?.payload) {
          clearForm();
          handleToggleModal();
          onSuccess();
        }
      });
    }
  };

  useEffect(() => {
    if (user) {
      const { email = '', firstName = '', lastName = '' } = user;
      setFormData({
        email,
        firstName,
        lastName,
        group: user?.customerGroup?.obj?.key || '',
        vendors:
          user.custom?.fields?.mc_vendors?.split(',').filter((v: string) => vendors.map((v) => v.key).includes(v)) ||
          [],
      });
    } else {
      clearForm();
    }
  }, [show]);

  useEffect(() => {
    if (
      (!user &&
        isValidEmail(formData.email) &&
        formData.firstName &&
        formData.lastName &&
        formData.group &&
        formData.vendors.length) ||
      (user &&
        formData.email === user.email &&
        (formData.lastName !== user.lastName ||
          formData.firstName !== user.firstName ||
          formData.group !== user.customerGroup?.obj?.key ||
          formData.vendors.sort().join(',') !== user.custom?.fields?.mc_vendors.split(',').sort().join(',')))
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateUserAction.typePrefix} />}
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createUserAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          {user ? (
            <FormattedMessage
              id='users_updateModal_title'
              defaultMessage='Update User "{email}"'
              values={{ email: user.email }}
            />
          ) : (
            <FormattedMessage id='users_createModal_title' defaultMessage='New User' />
          )}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {user ? (
                <FormattedMessage
                  id='users_updateModal_description'
                  defaultMessage='To update this user, please change values as needed below. Please be aware that the email cannot be changed.'
                />
              ) : (
                <FormattedMessage
                  id='users_createModal_description'
                  defaultMessage='To add a new User, please fill out the fields below.'
                />
              )}
            </DialogContentText>
            <TextField
              margin='dense'
              id='email'
              name='email'
              value={formData.email}
              disabled={!!user}
              onChange={handleChange}
              label={<FormattedMessage id='users_labels_email' defaultMessage='E-mail address' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='firstName'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              label={<FormattedMessage id='users_labels_firstName' defaultMessage='First Name' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='lastName'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              label={<FormattedMessage id='users_labels_lastName' defaultMessage='Last Name' />}
              fullWidth
            />
            <FormControl>
              <InputLabel id='groupLabel'>
                <FormattedMessage id='users_labels_group' defaultMessage='Group' />
              </InputLabel>
              <Select
                margin='dense'
                id='group'
                name='group'
                value={formData.group}
                onChange={handleGroupChange}
                disabled={currentUser.user?.email === formData.email}
                input={<OutlinedInput label={<FormattedMessage id='users_labels_group' defaultMessage='Group' />} />}
                labelId='groupLabel'
                fullWidth>
                {Object.entries(GROUPS)
                  .filter(([key]) => !!user || key !== 'ADMIN')
                  .map(([key, value]) => (
                    <MenuItem key={value} value={value}>
                      {key}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='vendorsLabel'>
                <FormattedMessage id='users_labels_vendors' defaultMessage='Vendors' />
              </InputLabel>
              <Select
                margin='dense'
                id='vendors'
                name='vendors'
                value={formData.vendors}
                onChange={handleVendorsChange}
                disabled={currentUser.user?.email === formData.email}
                multiple
                input={
                  <OutlinedInput label={<FormattedMessage id='users_labels_vendors' defaultMessage='Vendors' />} />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={vendors.find((c) => c.key === value)?.name} />
                    ))}
                  </Box>
                )}
                labelId='vendorsLabel'
                fullWidth>
                {vendors.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {((user && updateRequest?.status === 'pending') || createRequest?.status === 'pending') && (
          <LinearProgress color='primary' />
        )}
      </Dialog>
    </>
  );
};

export default CreateUpdateUserModal;
