import { MUI_THEME_DARK, MUI_THEME_LIGHT } from '@chronext/react-common';
import { Close } from '@mui/icons-material';
import { createTheme, CssBaseline, IconButton, PaletteMode, ThemeProvider } from '@mui/material';
import dot from 'dot-object';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch } from 'react-router-dom';
import { APPLICATION } from '../constants/application';
import deMessages from '../lang/de';
import enMessages from '../lang/en';
import { ClientRoute } from '../routing';
import { ClientRouteGroups } from '../routing/clientRoutes';
import { currentUserSelector, userAuthStateSelector } from '../selectors/user';
import { useAppDispatch, useAppSelector } from '../state';
import { getCurrentUserAction } from '../state/user/actions';
import styles from './App.module.scss';
import PasswordReset from './components/auth/PasswordReset';
import PrivateRoute from './components/auth/PrivateRoute';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import Homepage from './pages/home/Homepage';
import OrderDetails from './pages/orders/OrderDetails';
import Orders from './pages/orders/Orders';
import Users from './pages/users/Users';

const messages: { [param: string]: any } = {
  en: enMessages,
  de: deMessages,
};

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    components: {
      MuiDatePicker: {
        styleOverrides: {
          root: {
            backgroundColor: 'red',
          },
        },
      },
    },
    ...(mode === 'light' ? MUI_THEME_LIGHT : MUI_THEME_DARK),
  },
});

const App = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const userAuthState = useAppSelector(userAuthStateSelector);

  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useMemo(() => createTheme(getDesignTokens(currentUser.colorMode)), [currentUser.colorMode]);

  const locale = currentUser.language;
  const notistackRef: any = React.createRef();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!userAuthState.isAuthenticated) {
      dispatch(getCurrentUserAction());
    }
  }, [userAuthState.isAuthenticated]);

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <IntlProvider locale={locale} defaultLocale='en' messages={dot.dot(messages[locale] || {})}>
              <SnackbarProvider
                ref={notistackRef}
                action={(key) => (
                  <IconButton color='inherit' onClick={onClickDismiss(key)}>
                    <Close />
                  </IconButton>
                )}
                maxSnack={3}
                preventDuplicate>
                <div className={styles.body}>
                  <Sidebar open={mobileOpen} drawerWidth={200} onCloseCallback={handleSidebarToggle} />
                  <div className={styles.wrapper}>
                    <Navbar handleToggle={handleSidebarToggle} />
                    <main className={styles.main}>
                      <Switch>
                        <PrivateRoute
                          exact
                          path={ClientRoute.HOME}
                          component={Homepage}
                          groups={ClientRouteGroups.HOME}
                        />
                        <PrivateRoute
                          exact
                          path={ClientRoute.PASSWORD_RESET}
                          component={PasswordReset}
                          groups={ClientRouteGroups.PASSWORD_RESET}
                          needsAuth={false}
                        />
                        <PrivateRoute
                          exact
                          path={ClientRoute.USERS}
                          component={Users}
                          groups={ClientRouteGroups.USERS}
                        />
                        <PrivateRoute
                          exact
                          path={ClientRoute.ORDERS}
                          component={Orders}
                          groups={ClientRouteGroups.ORDERS}
                        />
                        <PrivateRoute
                          exact
                          path={ClientRoute.ORDER_DETAILS}
                          component={OrderDetails}
                          groups={ClientRouteGroups.ORDER_DETAILS}
                        />
                        <PrivateRoute
                          exact
                          path={ClientRoute.ORDER_DETAILS_ITEM}
                          component={OrderDetails}
                          groups={ClientRouteGroups.ORDER_DETAILS_ITEM}
                        />
                      </Switch>
                    </main>
                  </div>
                </div>
              </SnackbarProvider>
            </IntlProvider>
          </CssBaseline>
        </ThemeProvider>
      </BrowserRouter>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME || APPLICATION.APP_NAME}</title>
        <link rel='icon' href={`/assets/${process.env.REACT_APP_FAVICON_FILENAME}.ico`} />
        {[512, 256, 64, 48, 32, 16].map((size) => (
          <link
            key={size}
            rel='icon'
            type='image/png'
            sizes={`${size}x${size}`}
            href={`/assets/${process.env.REACT_APP_FAVICON_FILENAME}-${size}x${size}.png`}
          />
        ))}
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={`/assets/${process.env.REACT_APP_FAVICON_FILENAME}-180x180.png`}
        />
        <link rel='manifest' href='/manifest.json' />
        <link rel='mask-icon' href='/assets/safari-pinned-tab.svg' color='#000000' />
        <meta name='msapplication-TileColor' content='#e8b832' />
        <meta name='theme-color' content='#000000' />
      </Helmet>
    </>
  );
};

export default App;
