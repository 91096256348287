import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import UpdateTrackingForm from './UpdateTrackingForm';

type Props = {
  orderIds: string[];
  handleToggleModal: () => void;
  show: boolean;
  onSuccess: () => void;
};

const UpdateTrackingModal: React.FC<Props> = ({ orderIds, handleToggleModal, show, onSuccess }) => {
  return (
    <>
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='orders_updateModal_title'
            defaultMessage='Update selected {count, plural, one {order} other {orders}}?'
            values={{ count: orderIds.length }}
          />
        </DialogTitle>
        <DialogContent>
          <UpdateTrackingForm
            orderIds={orderIds}
            onSuccess={() => {
              handleToggleModal();
              onSuccess();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateTrackingModal;
