export const isAsyncType = (type: string): boolean => {
  return /(.*)\/(pending|fulfilled|rejected)$/.test(type);
};

export const isPendingOrFailure = (type: string): boolean => {
  return /(.*)\/(pending|rejected)$/.test(type);
};

export const isFulfilledRequest = (type: string): boolean => {
  return type.endsWith('/fulfilled');
};

export const isPendingRequest = (type: string): boolean => {
  return type.endsWith('/pending');
};

export const isRejectedRequest = (type: string): boolean => {
  return type.endsWith('/rejected');
};

export const getActionName = (type: string) => {
  const i = type.lastIndexOf('/');
  if (i < 0) {
    return type;
  }
  return type.slice(0, i);
};
