import { loadLocalStorageState } from '@chronext/react-common';
import { PaletteMode } from '@mui/material';
import { APIRoute, createPath } from '../routing';
import { del, get, post } from '../utils/httpUtils';

export const getCurrentUser = () => {
  const userToken = loadLocalStorageState('user_token');
  return post(APIRoute.USER_SELF, { userToken });
};

export const getColorMode = (): PaletteMode => {
  return loadLocalStorageState('user_colorMode');
};

export const getLanguage = () => {
  return loadLocalStorageState('user_language');
};

export type LoginRequestBody = {
  email: string;
  password: string;
};

export const login = (requestBody: LoginRequestBody) => {
  return post(APIRoute.AUTH_LOGIN, requestBody);
};

export const getUsers = (params: URLSearchParams) => {
  return get(APIRoute.USER, params);
};

export const logout = () => {
  return post(APIRoute.AUTH_LOGOUT);
};

export const requestPasswordReset = (email: string) => {
  return post(APIRoute.AUTH_PASSWORD_RESET_REQUEST, { email });
};

export type SetNewPasswordRequestBody = {
  emailToken: string;
  password: string;
};

export const setNewPassword = (requestBody: SetNewPasswordRequestBody) => {
  return post(APIRoute.AUTH_PASSWORD, requestBody);
};

export type CreateUserRequestBody = {
  email: string;
  firstName: string;
  lastName: string;
};

export const createUser = (payload: CreateUserRequestBody) => {
  return post(APIRoute.USER, payload);
};

export type UpdateUserRequestBody = {
  email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  vendors?: string[];
};

export const updateUser = (id: string, payload: UpdateUserRequestBody) => {
  const url = createPath({ path: APIRoute.USER_DETAILS, params: { id } });
  return post(url, payload);
};

export const deleteUser = (id: string) => {
  const url = createPath({ path: APIRoute.USER_DETAILS, params: { id } });
  return del(url);
};
