import { Link } from '@mui/material';
import React from 'react';
import { createPath, ExternalRoute } from '../../../routing';

type Props = {
  carrier: string;
  trackingNumber: string;
  showCarrierName?: boolean;
};

export const CARRIERS = [
  { value: 'dhl', label: 'DHL' },
  { value: 'fedex', label: 'FedEx' },
  { value: 'ferrari', label: 'Ferrari Group' },
  { value: 'ups', label: 'UPS' },
];

type CarrierRoute =
  | ExternalRoute.FEDEX_TRACKING
  | ExternalRoute.UPS_TRACKING
  | ExternalRoute.FERRARI_TRACKING
  | ExternalRoute.DHL_TRACKING;

const CARRIER_ROUTES: { [param: string]: CarrierRoute } = {
  dhl: ExternalRoute.DHL_TRACKING,
  fedex: ExternalRoute.FEDEX_TRACKING,
  ferrari: ExternalRoute.FERRARI_TRACKING,
  ups: ExternalRoute.UPS_TRACKING,
};

export const getCarrierName = (carrier: string) => {
  return (carrier && CARRIERS.find((c) => c.value === carrier)?.label) || carrier;
};

const TrackingLink: React.FC<Props> = ({ carrier, trackingNumber, showCarrierName }) => {
  let trackingPath = '';
  if (trackingNumber && carrier in CARRIER_ROUTES) {
    trackingPath = createPath({
      path: CARRIER_ROUTES?.[carrier],
      params: { trackingNumber },
    });
  }
  const renderCarrierName = carrier && <>&nbsp;({getCarrierName(carrier)})</>;
  return trackingPath ? (
    <Link target='_blank' href={trackingPath} underline='none'>
      {trackingNumber}
      {showCarrierName && renderCarrierName}
    </Link>
  ) : (
    <>
      {trackingNumber}
      {showCarrierName && renderCarrierName}
    </>
  );
};

export default TrackingLink;
