import { Button, MenuItem, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateOrderAction, updateOrdersAction } from '../../../../state/orders/actions';
import { CARRIERS } from '../../../components/links/TrackingLink';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

const initialTrackingFormData = {
  vendorCarrier: '',
  vendorTrackingNo: '',
};

type Props = {
  orderIds: string[];
  onSuccess: () => void;
};

const UpdateTrackingForm: React.FC<Props> = ({ orderIds, onSuccess }) => {
  const dispatch = useAppDispatch();
  const updateRequest = useAppSelector((state) => requestSelector(state, updateOrderAction.typePrefix));
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);

  const [trackingFormData, setTrackingFormData] = React.useState(initialTrackingFormData);
  const handleTrackingFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingFormData({ ...trackingFormData, [event.target.name]: event.target.value });
  };

  const clearForm = () => {
    setTrackingFormData(initialTrackingFormData);
  };

  const submitForm = () => {
    dispatch(
      updateOrdersAction({
        references: orderIds,
        requestBody: trackingFormData,
      }),
    ).then((response) => {
      setShowUpdateSnackbar(true);
      if (response?.payload) {
        clearForm();
        onSuccess();
      }
    });
  };

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateOrdersAction.typePrefix} />}

      <Stack spacing={1}>
        <TextField
          margin='dense'
          size='small'
          id='vendorTrackingNo'
          name='vendorTrackingNo'
          value={trackingFormData.vendorTrackingNo}
          onChange={handleTrackingFormChange}
          label={<FormattedMessage id='orders_labels_vendorTrackingNo' defaultMessage='Tracking No.' />}
          fullWidth
        />
        <TextField
          margin='dense'
          size='small'
          id='vendorCarrier'
          name='vendorCarrier'
          value={trackingFormData.vendorCarrier}
          onChange={handleTrackingFormChange}
          select
          label={<FormattedMessage id='orders_labels_vendorCarrier' defaultMessage='Carrier' />}
          fullWidth>
          {CARRIERS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Button
          disabled={
            !trackingFormData.vendorTrackingNo || !trackingFormData.vendorCarrier || updateRequest.status === 'pending'
          }
          color='success'
          variant='contained'
          onClick={submitForm}>
          <FormattedMessage id='common_submit' defaultMessage='Submit' />
        </Button>
      </Stack>
    </>
  );
};

export default UpdateTrackingForm;
