import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ClientRoute } from '../../../routing';
import { requestSelector } from '../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../state';
import { setNewPasswordAction } from '../../../state/user/actions';
import LoadingButton from '../buttons/LoadingButton';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';

const initialFormData = {
  password: '',
  repeatPassword: '',
};

const PasswordReset: React.FC = () => {
  const dispatch = useAppDispatch();
  const setPasswordRequest = useAppSelector((state) => requestSelector(state, setNewPasswordAction.typePrefix));
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const emailToken = searchParams.get('token') || '';

  const [showSnackbar, setShowSnackbar] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    dispatch(setNewPasswordAction({ emailToken, password: formData.password })).then((response) => {
      setShowSnackbar(true);
      if (response.payload) {
        clearForm();
        history.push(ClientRoute.HOME);
      }
    });
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && isFormValid) {
      submitForm();
    }
  };

  useEffect(() => {
    if (formData.password === formData.repeatPassword && formData.password.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  if (!emailToken) {
    return null;
  }

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={setNewPasswordAction.typePrefix} />}
      <Stack height='100%' justifyContent='center' alignItems='center'>
        <Card variant='outlined' sx={{ width: '350px' }}>
          <CardContent>
            <Stack spacing={2} direction='column'>
              <Typography variant='h5' component='div'>
                <FormattedMessage id='auth_passwordReset_title' defaultMessage='Set New Password' />
              </Typography>
              <FormControl variant='outlined'>
                <InputLabel>
                  <FormattedMessage id='auth_labels_password' defaultMessage='Password' />
                </InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  margin='dense'
                  id='password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  label={<FormattedMessage id='auth_labels_password' defaultMessage='Password' />}
                  fullWidth
                />
              </FormControl>
              <FormControl variant='outlined'>
                <InputLabel>
                  <FormattedMessage id='auth_labels_newPassword' defaultMessage='New Password' />
                </InputLabel>
                <OutlinedInput
                  type={showRepeatPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                        edge='end'>
                        {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  margin='dense'
                  id='repeatPassword'
                  name='repeatPassword'
                  value={formData.repeatPassword}
                  onChange={handleChange}
                  label={<FormattedMessage id='auth_labels_repeatPassword' defaultMessage='Repeat Password' />}
                  fullWidth
                />
              </FormControl>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <LoadingButton
              isLoading={setPasswordRequest?.status === 'pending'}
              onClick={submitForm}
              disabled={setPasswordRequest.status === 'pending' || !isFormValid}>
              <FormattedMessage id='auth_buttons_setPassword' defaultMessage='Set Password' />
            </LoadingButton>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default PasswordReset;
