import { PaletteMode } from '@mui/material';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUser,
  CreateUserRequestBody,
  deleteUser,
  getCurrentUser,
  getUsers,
  login,
  LoginRequestBody,
  logout,
  requestPasswordReset,
  setNewPassword,
  SetNewPasswordRequestBody,
  updateUser,
  UpdateUserRequestBody,
} from '../../api/user';
import { UserActions } from './types';

export const getCurrentUserAction = createAsyncThunk(UserActions.getCurrentUser, async () => {
  const response = await getCurrentUser();
  return response?.data;
});

export const setLanguageAction = createAction<string>(UserActions.setLanguage);

export const setColorModeAction = createAction<PaletteMode>(UserActions.setColorMode);

export const setViewAsGroupAction = createAction<string>(UserActions.setViewAsGroup);

export const loginAction = createAsyncThunk(UserActions.login, async (payload: LoginRequestBody) => {
  const response = await login(payload);
  return response.data;
});

export const getUsersAction = createAsyncThunk(UserActions.getUsers, async (params: URLSearchParams) => {
  const response = await getUsers(params);
  return response.data;
});

export const logoutAction = createAsyncThunk(UserActions.logout, async () => {
  const response = await logout();
  return response.data;
});

export const requestPasswordResetAction = createAsyncThunk(UserActions.requestPasswordReset, async (email: string) => {
  const response = await requestPasswordReset(email);
  return response.data;
});

export const setNewPasswordAction = createAsyncThunk(
  UserActions.setNewPassword,
  async (payload: SetNewPasswordRequestBody) => {
    const response = await setNewPassword(payload);
    return response.data;
  },
);

export const createUserAction = createAsyncThunk(UserActions.createUser, async (payload: CreateUserRequestBody) => {
  const response = await createUser(payload);
  return response.data;
});

type UpdateUserActionParams = {
  id: string;
  requestBody: UpdateUserRequestBody;
};

export const updateUserAction = createAsyncThunk(UserActions.updateUser, async (params: UpdateUserActionParams) => {
  const response = await updateUser(params.id, params.requestBody);
  return response.data;
});

export const deleteUserAction = createAsyncThunk(UserActions.deleteUser, async (id: string) => {
  const response = await deleteUser(id);
  return response.data;
});
