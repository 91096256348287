import { TranslateOutlined } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { setLanguageAction } from '../../../../state/user/actions';

const LANGUAGES: { [param: string]: string } = {
  en: 'English',
  de: 'Deutsch',
};

const LanguageSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language: string) => {
    dispatch(setLanguageAction(language));
    handleClose();
  };

  return (
    <>
      <Button
        className='language-switcher'
        color='inherit'
        size='small'
        variant='outlined'
        onClick={handleOpen}
        startIcon={<TranslateOutlined />}>
        {LANGUAGES?.[currentUser?.language]}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        variant='menu'>
        {Object.keys(LANGUAGES).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
            selected={currentUser.language === language}>
            {LANGUAGES[language]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
