import { Preview } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { GROUPS } from '../../../../constants/groups';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { setViewAsGroupAction } from '../../../../state/user/actions';

const VIEW_GROUPS: Record<string, string> = { ...GROUPS, RESET: 'Reset' };

const UserGroupSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupChange = (group: string) => {
    dispatch(setViewAsGroupAction(group));
    handleClose();
  };

  return (
    <>
      <Button
        className='language-switcher'
        color='inherit'
        size='small'
        variant='outlined'
        onClick={handleOpen}
        startIcon={<Preview />}>
        View as
        {currentUser?.viewAsGroup
          ? `: ${Object.entries(VIEW_GROUPS).find(([, name]) => name === currentUser.viewAsGroup)?.[0]}`
          : ''}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        variant='menu'>
        {Object.entries(VIEW_GROUPS)
          .filter(([key]) => key !== 'ADMIN')
          .map(([key, name]) => (
            <MenuItem key={key} onClick={() => handleGroupChange(name)} selected={currentUser.viewAsGroup === name}>
              {key}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default UserGroupSwitcher;
