import { LocalShipping } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridRenderCellParams, GridRowParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../state';
import { getOpenShipmentOrdersAction } from '../../../state/orders/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import { generateOrderDetailsPath, ORDERS_TABLE_COLUMNS } from './components/ordersTableHelper';
import OrdersTableToolbar from './components/OrdersTableToolbar';
import UpdateTrackingModal from './components/UpdateTrackingModal';
import styles from './Orders.module.scss';

const ordersTableColumns: ServerSidePaginatedTableColDef[] = [
  ...ORDERS_TABLE_COLUMNS,
  {
    id: 'orders_labels_orderDate',
    headerName: 'Order Date',
    field: 'orderDate',
    renderCell: (params: GridRenderCellParams) => formatUTCToLocal(params.row.orderDate?.date),
    filterable: false,
  },
];

const OrdersAcceptanceTable: React.FC = () => {
  const orders = useAppSelector((store) => store.order.orders);
  const totalCount = useAppSelector((store) => store.order.ordersTotal);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(ordersTableColumns);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filterVendor, setFilterVendor] = useState<string>('all');
  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const history = useHistory();

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vendor = event.target.value;
    setFilterVendor(vendor);
    setSearchParams({ ...searchParams, vendorKey: vendor === 'all' ? undefined : vendor });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSearchParams({
      ...searchParams,
      s: sortModel[0]?.field,
      d: sortModel[0]?.sort,
    });
  }, []);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      filter: searchText,
    });
  }, [searchText]);

  const actionsColumn: ServerSidePaginatedTableColDef = {
    id: 'common_actions',
    headerName: 'Actions',
    field: 'actions',
    sortable: false,
    minWidth: 220,
    renderCell: (params: GridRenderCellParams) => (
      <Button
        startIcon={<LocalShipping />}
        color='primary'
        variant='outlined'
        onClick={(ev) => {
          ev?.stopPropagation();
          ev?.preventDefault();
          setSelectedOrders([params.row?.id]);
          setShowTrackingModal(true);
        }}>
        <FormattedMessage id='orders_details_updateTracking' defaultMessage='Update Tracking' />
      </Button>
    ),
  };

  useEffect(() => {
    setTableColumns([...ordersTableColumns, actionsColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getOpenShipmentOrdersAction.typePrefix} />
      {showTrackingModal && selectedOrders && (
        <UpdateTrackingModal
          show={showTrackingModal}
          orderIds={selectedOrders}
          handleToggleModal={() => setShowTrackingModal(!showTrackingModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      <ServerSidePaginatedTable
        rows={orders}
        columns={tableColumns}
        totalCount={totalCount}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getOpenShipmentOrdersAction}
        initPageSize={50}
        searchParams={searchParams}
        getRowHeight={() => 'auto'}
        onRowClick={(params: GridRowParams) => {
          const path = generateOrderDetailsPath(params);
          history.push(path);
        }}
        components={{
          Toolbar: OrdersTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            searchText,
            onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
            filterVendor,
            handleFilterChange,
            showEditTracking: selectedRows.length > 0,
            handleEditTracking: () => setShowTrackingModal(true),
          },
        }}
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
        checkboxSelection
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
          setSelectedOrders(newSelection.map((id) => id.toString()));
        }}
        selectionModel={selectedRows}
      />
    </div>
  );
};

export default OrdersAcceptanceTable;
