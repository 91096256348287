import { getPriceLocaleString } from '@chronext/react-common';
import { Avatar, Button, Card, CardContent, CardHeader, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { GROUPS } from '../../../constants/groups';
import { ACCEPTANCE_STATUS, STATUS_ACTION_MAP } from '../../../constants/orders';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { requestSelector } from '../../../selectors/request';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getOrderAction, getOrderItemAction, updateOrderAction } from '../../../state/orders/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import StatusBadge from '../../components/badges/StatusBadge';
import TrackingLink from '../../components/links/TrackingLink';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ConfirmUpdateAcceptanceStatusModal from './components/ConfirmUpdateAcceptanceStatusModal';
import UpdateTrackingForm from './components/UpdateTrackingForm';

type Params = {
  [key: string]: string;
};

const initialUpdateFormData = {
  vendorComment: '',
};

const OrderDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { reference, lineNo } = useParams<Params>();
  const currentUser = useAppSelector(currentUserSelector);
  const { language } = currentUser;
  const canApproveOrders = useAppSelector((state) =>
    isAuthorizedSelector(
      state,
      ClientRouteGroups.ORDER_DETAILS.filter((g) => g !== GROUPS.LOGISTICS),
    ),
  );
  const orders = useAppSelector((store) => store.order.order);
  const updateRequest = useAppSelector((state) => requestSelector(state, updateOrderAction.typePrefix));
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [showAcceptanceStatusModal, setShowAcceptanceStatusModal] = useState(false);
  const [selectedAcceptanceStatus, setSelectedAcceptanceStatus] = useState<string>('');

  const [updateFormData, setUpdateFormData] = React.useState(initialUpdateFormData);

  useEffect(() => {
    if (lineNo) {
      dispatch(getOrderItemAction({ reference, lineNo }));
    } else {
      dispatch(getOrderAction(reference));
    }
  }, [reference, lineNo]);

  if (!orders?.length) {
    return null;
  }

  const lineNumber = lineNo ? parseInt(`${lineNo}0000`, 10) : 10000;
  const order = orders.length > 1 ? orders?.find((order) => order.lineNo === lineNumber) : orders[0];

  if (!order) {
    return null;
  }

  const handleUpdateFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateFormData({ ...updateFormData, [event.target.name]: event.target.value });
  };

  const clearForm = () => {
    setUpdateFormData(initialUpdateFormData);
  };

  const submitForm = () => {
    dispatch(
      updateOrderAction({
        reference: order.id,
        requestBody: updateFormData,
      }),
    ).then((response) => {
      setShowUpdateSnackbar(true);
      if (response?.payload && !response?.payload?.errors) {
        dispatch(getOrderAction(reference));
        clearForm();
      }
    });
  };

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateOrderAction.typePrefix} />}
      {lineNo ? (
        <ActionStatusSnackbar actionName={getOrderItemAction.typePrefix} />
      ) : (
        <ActionStatusSnackbar actionName={getOrderAction.typePrefix} />
      )}
      {canApproveOrders && showAcceptanceStatusModal && selectedAcceptanceStatus && (
        <ConfirmUpdateAcceptanceStatusModal
          show={showAcceptanceStatusModal}
          status={selectedAcceptanceStatus}
          reference={order.id}
          orderNo={order.orderNo}
          lineNo={order.lineNo.toString().replace(/0+$/, '')}
          handleToggleModal={() => setShowAcceptanceStatusModal(!showAcceptanceStatusModal)}
          onSuccess={() => dispatch(getOrderAction(reference))}
        />
      )}
      <Stack spacing={2}>
        <Card>
          <CardHeader
            title={
              <Stack direction='row' justifyContent='space-between'>
                <FormattedMessage id='orders_details_title' defaultMessage='Order {reference}' values={{ reference }} />
                {/* <StatusBadge status={order.orderStatus.toLowerCase()} /> TODO re-add when cancellations are reflected */}
              </Stack>
            }
          />
          <CardContent>
            <Stack direction='row' spacing={1}>
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                <FormattedMessage id='orders_labels_orderDate' defaultMessage='Order Date' />:
              </Typography>
              <Typography variant='body1'>{formatUTCToLocal(order.orderDate?.date)}</Typography>
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={<FormattedMessage id='orders_details_cardTitles_item' defaultMessage='Item' />} />
          <CardContent>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <Avatar
                variant='rounded'
                style={{ width: '150px', height: '150px', border: '1px solid #7e7e7e' }}
                src={order.imageUrl}
                alt={order.variantCode}
              />
              <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_brand' defaultMessage='Brand' />:
                  </Typography>
                  <Typography variant='body1'>{order.brand}</Typography>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_model' defaultMessage='Model' />:
                  </Typography>
                  <Typography variant='body1'>{order.model}</Typography>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_reference' defaultMessage='Reference' />:
                  </Typography>
                  <Typography variant='body1'>{order.reference}</Typography>
                </Stack>
              </Stack>
              <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_vendorItemId' defaultMessage='Item ID' />:
                  </Typography>
                  <Typography variant='body1'>{order.vendorItemId}</Typography>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_vendorPrice' defaultMessage='Vendor Price' />:
                  </Typography>
                  <Typography variant='body1'>
                    {getPriceLocaleString(order.vendorPrice * 100, `${language}-DE`, 'EUR')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={<FormattedMessage id='orders_details_cardTitles_acceptance' defaultMessage='Acceptance' />}
          />
          <CardContent>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={3}>
              <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={2}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='common_status' defaultMessage='Status' />:
                  </Typography>
                  {order.acceptanceStatus ? (
                    <StatusBadge status={order.acceptanceStatus.toLowerCase()} />
                  ) : (
                    <Typography variant='body1'>-</Typography>
                  )}
                </Stack>
                <Stack direction='row' spacing={1}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_firstAcceptanceUser' defaultMessage='First Acceptance User' />:
                  </Typography>
                  <Typography variant='body1'>{order.firstAcceptanceUser?.email}</Typography>
                </Stack>
                <Stack direction='row' spacing={1}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_firstAcceptanceDate' defaultMessage='First Acceptance Date' />:
                  </Typography>
                  <Typography variant='body1'>{formatUTCToLocal(order.firstAcceptanceDate)}</Typography>
                </Stack>
              </Stack>
              {canApproveOrders && (
                <Stack spacing={1} alignItems='left' justifyContent='center' my={1}>
                  {(!order.acceptanceStatus ||
                    moment(order.firstAcceptanceDate).isAfter(moment().subtract(24, 'hours'))) &&
                    Object.entries(STATUS_ACTION_MAP)
                      ?.filter(([key]) => order.acceptanceStatus.toLowerCase() !== key)
                      ?.map(([, action]) => {
                        return (
                          <StatusBadge
                            key={nanoid()}
                            status={action}
                            showIcon
                            onClick={(ev) => {
                              ev?.stopPropagation();
                              ev?.preventDefault();
                              setShowAcceptanceStatusModal(true);
                              setSelectedAcceptanceStatus(action);
                            }}
                          />
                        );
                      })}
                </Stack>
              )}
            </Stack>
          </CardContent>
        </Card>
        {order.acceptanceStatus.toLowerCase() === ACCEPTANCE_STATUS.Approved && (
          <Card>
            <CardHeader
              title={<FormattedMessage id='orders_details_cardTitles_shipment' defaultMessage='Shipment' />}
            />
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={3}>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                      <FormattedMessage id='common_status' defaultMessage='Status' />:
                    </Typography>
                    {order.shipmentStatus ? (
                      <StatusBadge status={order.shipmentStatus.toLowerCase()} />
                    ) : (
                      <Typography variant='body1'>-</Typography>
                    )}
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                      <FormattedMessage id='orders_labels_vendorTrackingNo' defaultMessage='Tracking No.' />:
                    </Typography>
                    {order.vendorTrackingNo && order.vendorCarrier ? (
                      <TrackingLink
                        carrier={order.vendorCarrier}
                        trackingNumber={order.vendorTrackingNo}
                        showCarrierName
                      />
                    ) : (
                      <Typography variant='body1'>-</Typography>
                    )}
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                      <FormattedMessage
                        id='orders_labels_vendorOrderTrackingUser'
                        defaultMessage='Vendor Order Tracking User'
                      />
                      :
                    </Typography>
                    <Typography variant='body1'>{order.vendorOrderTrackingUser?.email}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                      <FormattedMessage
                        id='orders_labels_vendorOrderTrackingDate'
                        defaultMessage='Vendor Order Tracking Date'
                      />
                      :
                    </Typography>
                    <Typography variant='body1'>{formatUTCToLocal(order.vendorOrderTrackingDate)}</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_details_updateTracking' defaultMessage='Update Tracking' />
                  </Typography>
                  <UpdateTrackingForm orderIds={[order.id]} onSuccess={() => dispatch(getOrderAction(reference))} />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader title={<FormattedMessage id='orders_details_cardTitles_other' defaultMessage='Other' />} />
          <CardContent>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={3}>
              <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={1}>
                  <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='orders_labels_vendorComment' defaultMessage='Comment' />:
                  </Typography>
                  <Typography variant='body1'>{order.vendorComment}</Typography>
                </Stack>
              </Stack>
              <Stack direction='column' spacing={1}>
                <TextField
                  margin='dense'
                  id='vendorComment'
                  name='vendorComment'
                  value={updateFormData.vendorComment}
                  onChange={handleUpdateFormChange}
                  label={<FormattedMessage id='orders_labels_vendorComment' defaultMessage='Comment' />}
                  fullWidth
                  multiline
                  minRows={3}
                />
                <Button
                  disabled={!updateFormData.vendorComment || updateRequest.status === 'pending'}
                  color='success'
                  variant='contained'
                  onClick={submitForm}>
                  <FormattedMessage id='common_submit' defaultMessage='Submit' />
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default OrderDetails;
