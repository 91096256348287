import { Avatar } from '@mui/material';
import { getGridStringOperators, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import React from 'react';
import { ClientRoute, createPath } from '../../../../routing';

export const ORDERS_TABLE_COLUMNS = [
  {
    id: 'orders_labels_image',
    headerName: 'Image',
    field: 'imageUrl',
    renderCell: (params: GridRenderCellParams) => (
      <Avatar
        variant='rounded'
        style={{ width: '100px', height: '100px' }}
        src={params?.row.imageUrl}
        alt={params.row.variantCode}
      />
    ),
    minWidth: 120,
    sortable: false,
    filterable: false,
  },
  {
    id: 'orders_labels_orderNo',
    headerName: 'Order No.',
    field: 'orderNo',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'orders_labels_vendorItemId',
    headerName: 'Item ID',
    field: 'vendorItemId',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'orders_labels_brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 120,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'orders_labels_model',
    headerName: 'Model',
    field: 'model',
    minWidth: 120,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'orders_labels_reference',
    headerName: 'Reference',
    field: 'reference',
    sortable: false,
    minWidth: 120,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
];

export const generateOrderDetailsPath = (params: GridRowParams) => {
  let path;
  if (params.row.lineNo.toString() !== '10000') {
    path = createPath({
      path: ClientRoute.ORDER_DETAILS_ITEM,
      params: { reference: params.row.orderNo, lineNo: params.row.lineNo.toString().replace(/0+$/, '') },
    });
  } else {
    path = createPath({
      path: ClientRoute.ORDER_DETAILS,
      params: { reference: params.row.orderNo },
    });
  }
  return path;
};
