import { UserObject } from '../user/types';

export enum OrderActions {
  getOrders = 'order/getOrders',
  getPendingOrders = 'order/getPendingOrders',
  getOpenShipmentOrders = 'order/getOpenShipmentOrders',
  getOrder = 'order/getOrder',
  getOrderItem = 'order/getOrderItem',
  updateOrder = 'order/updateOrder',
  updateOrders = 'order/updateOrders',
  approveOrder = 'order/approveOrder',
  rejectOrder = 'order/rejectOrder',
}

export type Order = {
  id: string;
  documentNo: string;
  lineNo: number;
  vendor: {
    vendorKey: string;
    name: string;
  };
  orderDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  orderNo: string;
  variantCode: string;
  imageUrl: string;
  vendorItemId: string;
  brand: string;
  model: string;
  reference: string;
  vendorPrice: number;
  customerPrice: number;
  payoutAmount: number;
  acceptanceStatus: string;
  shipmentStatus: string;
  orderStatus: string;
  returnsInformation: string;
  firstAcceptanceUser?: UserObject;
  firstAcceptanceDate?: string;
  vendorOrderTrackingUser?: UserObject;
  vendorOrderTrackingDate?: string;
  vendorCarrier: string;
  vendorTrackingNo: string;
  vendorComment: string;
};

export type OrderState = {
  orders: Order[];
  ordersTotal: number;
  order?: Order[];
};
