import { PathArgs as APIPathArgs, APIRoute } from './apiRoutes';
import { PathArgs as ClientPathArgs, ClientRoute } from './clientRoutes';
import { PathArgs as ExternalPathArgs, ExternalRoute } from './externalRoutes';

export { APIRoute, ClientRoute, ExternalRoute };

type PathArgs = APIPathArgs | ClientPathArgs | ExternalPathArgs;

export type PathArgsWithParams = Extract<PathArgs, { path: string; params: any }>;

export function createPath(args: PathArgs) {
  // For request without any parameter
  if (!args.hasOwnProperty('params')) return args.path;

  // Create a path by replacing params
  return Object.entries((args as PathArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) => previousValue.replaceAll(`:${param}`, '' + value),
    args.path,
  );
}
