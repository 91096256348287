import { loadLocalStorageState } from '@chronext/react-common';
import { APP_CONFIG } from '../config/app';
import api, { RequestConfig } from '../clients/api';

const getRequestConfig = (requestConfig?: RequestConfig) => {
  const defaultConfig: RequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 10000,
  };

  const auth = loadLocalStorageState('user_token') || '';
  if (auth && defaultConfig.headers) {
    defaultConfig.headers['X-AUTH-TOKEN'] = auth;
  }

  // Add baseURl for production/staging
  if (APP_CONFIG.backendAPI) {
    defaultConfig.baseURL = APP_CONFIG.backendAPI;
  }

  if (!requestConfig) {
    return defaultConfig;
  }
  return {
    ...defaultConfig,
    ...requestConfig,
  };
};

export const get = async (url: string, params?: URLSearchParams | null, requestConfig?: RequestConfig) => {
  return api.get(url, { ...getRequestConfig(requestConfig), params: params });
};

export const post = async (url: string, data?: any, requestConfig?: RequestConfig) => {
  let postData = {};
  if (data) {
    if (
      requestConfig &&
      requestConfig.headers &&
      requestConfig.headers['Content-Type'] &&
      requestConfig.headers['Content-Type'] !== 'application/json'
    ) {
      postData = data;
    } else {
      postData = JSON.stringify(data);
    }
  }
  return api.post(url, postData, getRequestConfig(requestConfig));
};

export const put = async (url: string, data: any, requestConfig?: RequestConfig) => {
  return api.put(url, JSON.stringify(data), getRequestConfig(requestConfig));
};

export const patch = async (url: string, data: any, requestConfig?: RequestConfig) => {
  return api.patch(url, JSON.stringify(data), getRequestConfig(requestConfig));
};

export const del = async (url: string, data?: any, requestConfig?: RequestConfig) => {
  let deleteData = {};
  if (data) {
    if (
      requestConfig &&
      requestConfig.headers &&
      requestConfig.headers['Content-Type'] &&
      requestConfig.headers['Content-Type'] !== 'application/json'
    ) {
      deleteData = data;
    } else {
      deleteData = JSON.stringify(data);
    }
  }
  return api.delete(url, { ...getRequestConfig(requestConfig), data: deleteData });
};
