import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoginRequestBody } from '../../../api/user';
import { requestSelector } from '../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../state';
import { loginAction, requestPasswordResetAction } from '../../../state/user/actions';
import { isValidEmail } from '../../../utils/textUtils';
import LoadingButton from '../buttons/LoadingButton';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';

const initialFormData: LoginRequestBody = {
  email: '',
  password: '',
};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const loginRequest = useAppSelector((state) => requestSelector(state, loginAction.typePrefix));
  const requestPasswordResetRequest = useAppSelector((state) =>
    requestSelector(state, requestPasswordResetAction.typePrefix),
  );

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setShowPasswordResetMessage(false);
  };

  const submitForm = () => {
    dispatch(loginAction(formData)).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response?.payload?.errors) {
        clearForm();
      }
    });
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && isFormValid) {
      submitForm();
    }
  };

  const handleRequestPasswordReset = () => {
    dispatch(requestPasswordResetAction(formData.email)).then(() => {
      setShowPasswordResetMessage(true);
      clearForm();
    });
  };

  useEffect(() => {
    if (formData.email && isValidEmail(formData.email) && formData.password) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={loginAction.typePrefix} />}
      <Stack height='100%' justifyContent='center' alignItems='center'>
        <Card variant='outlined' sx={{ width: '350px' }}>
          <CardContent>
            <Stack spacing={2} direction='column'>
              <Typography variant='h5' component='div'>
                <FormattedMessage id='auth_login_title' defaultMessage='Login' />
              </Typography>
              <TextField
                margin='dense'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                label={<FormattedMessage id='auth_labels_email' defaultMessage='E-Mail' />}
                fullWidth
              />
              <FormControl variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  <FormattedMessage id='auth_labels_password' defaultMessage='Password' />
                </InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  margin='dense'
                  id='password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  label={<FormattedMessage id='auth_labels_password' defaultMessage='Password' />}
                  fullWidth
                />
              </FormControl>
              {showPasswordResetMessage && (
                <Alert severity='success' variant='filled'>
                  <FormattedMessage
                    id='auth_passwordReset_successMessage'
                    defaultMessage='If your email address is stored in our system, you will receive an email from us to reset your password. Please click on the link provided to reset your password.'
                  />
                </Alert>
              )}
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <LoadingButton
              isLoading={requestPasswordResetRequest?.status === 'pending'}
              color='info'
              onClick={() => {
                handleRequestPasswordReset();
                console.log('Reset Password');
              }}
              disabled={!isValidEmail(formData.email)}>
              <FormattedMessage id='auth_buttons_passwordReset' defaultMessage='Reset Password' />
            </LoadingButton>
            <LoadingButton
              isLoading={loginRequest?.status === 'pending'}
              onClick={submitForm}
              disabled={loginRequest.status === 'pending' || !isFormValid}>
              <FormattedMessage id='auth_buttons_login' defaultMessage='Log In' />
            </LoadingButton>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default Login;
