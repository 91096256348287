import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { APP_CONFIG } from '../../../../config/app';
import { APPLICATION } from '../../../../constants/application';
import { ClientRoute } from '../../../../routing';
import styles from './Logo.module.scss';

const Logo: React.FC = () => {
  const theme = useTheme();

  return (
    <NavLink className={styles.wrapper} to={ClientRoute.HOME}>
      <Stack spacing={1} direction='row' alignItems='center' justifyContent='center' width='100%'>
        <SvgIcon
          className={styles.logo__icon}
          width='64'
          height='64'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 64 64'>
          <rect
            x='0.5'
            y='0.5'
            width='63'
            height='63'
            rx='4.5'
            fill={APP_CONFIG.isProd ? '#000' : '#fff'}
            stroke={APP_CONFIG.isProd ? '#fff' : '#000'}
          />
          <path
            d='M33.4207 46.2321C29.1139 46.2321 25.5812 44.8873 22.9286 42.2304C20.2761 39.5735 18.9193 35.9467 18.8989 31.4519C18.8989 27.2465 20.2394 23.7501 22.8798 21.0483C25.5201 18.3506 29.0487 16.9733 33.3555 16.9488C37.479 16.9488 41.195 18.2039 44.4058 20.6734L50.3424 14.1085C45.62 10.0538 39.9074 8 33.3596 8C26.775 8 21.1847 10.282 16.7353 14.7809C12.2858 19.2797 10.0204 24.9115 10 31.513C10 38.2613 12.2614 43.9542 16.719 48.4326C21.1766 52.9111 26.775 55.1931 33.3596 55.2135C39.9278 55.2135 45.6404 53.1597 50.3424 49.105L44.4098 42.5483C41.3172 44.9933 37.6257 46.2321 33.4207 46.2321Z'
            fill={APP_CONFIG.isProd ? '#fff' : '#000'}
          />
        </SvgIcon>
        <Typography color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}>{APPLICATION.APP_NAME}</Typography>
      </Stack>
    </NavLink>
  );
};

Logo.propTypes = {
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  dark: false,
};

export default Logo;
