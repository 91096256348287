export const capitalizeFirstLetter = (word: string): string => {
  return word.replace(/^\w/, (c: string) => c.toLocaleUpperCase());
};

export const replaceNumbersAndLettersInString = (word: string): string => {
  return word.replaceAll('#', getRandomNumber).replaceAll('*', getRandomLetter);
};

const replacementLetters = 'ABCDEFGHJKLMNPQRSTUVWXYZ';
const replacementNumbers = '23456789';

const getRandomNumber = () => {
  return replacementNumbers[Math.floor(Math.random() * replacementNumbers.length)];
};

const getRandomLetter = () => {
  return replacementLetters[Math.floor(Math.random() * replacementLetters.length)];
};

export const getPriceLocaleString = (value: number, language = 'en', country: string, currency: string) => {
  const locale = `${language}-${country}`;

  if (!/[a-z]{2}-[a-z]{2}$/i.test(locale)) {
    return value;
  }

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  if (currency === 'USD') {
    options.currencyDisplay = 'code';
  }

  return value.toLocaleString(locale, options).replace(/\s/g, '\u00a0');
};

export const isValidEmail = (email: string): boolean => {
  return /^[\w.-]+@[\w.-]+$/i.test(email);
};
