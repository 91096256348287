import { ACCEPTANCE_STATUS } from '../constants/orders';

export enum APIRoute {
  AUTH_LOGIN = '/api/mc/user/login',
  AUTH_LOGOUT = '/api/mc/user/logout',
  AUTH_PASSWORD_RESET_REQUEST = '/api/mc/user/password/request',
  AUTH_PASSWORD = '/api/mc/user/password',
  USER_SELF = '/api/mc/user/self',
  USER = '/api/mc/user',
  USER_DETAILS = '/api/mc/user/:id',
  ORDER = '/api/mc/order',
  ORDER_ACCEPTANCE = '/api/mc/order/acceptance/:status',
  ORDER_SHIPPING = '/api/mc/order/shipping/:status',
  ORDER_DETAILS = '/api/mc/order/:reference',
  ORDER_DETAILS_ITEM = '/api/mc/order/:reference/:lineNo',
  ORDER_STATUS = '/api/mc/order/status/:reference',
}

export type PathArgs =
  | { path: APIRoute.AUTH_LOGIN }
  | { path: APIRoute.AUTH_LOGOUT }
  | { path: APIRoute.AUTH_PASSWORD_RESET_REQUEST }
  | { path: APIRoute.AUTH_PASSWORD }
  | { path: APIRoute.USER_SELF }
  | { path: APIRoute.USER }
  | { path: APIRoute.USER_DETAILS; params: { id: string } }
  | { path: APIRoute.ORDER }
  | { path: APIRoute.ORDER_ACCEPTANCE; params: { status: ACCEPTANCE_STATUS } }
  | { path: APIRoute.ORDER_SHIPPING; params: { status: string } }
  | {
      path: APIRoute.ORDER_DETAILS | APIRoute.ORDER_STATUS;
      params: { reference: string };
    }
  | {
      path: APIRoute.ORDER_DETAILS_ITEM;
      params: { reference: string; lineNo: string };
    };
