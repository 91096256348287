import { Item, MenuItem } from '../Sidebar';
import styles from './SidebarItem.module.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useAppSelector } from '../../../../state';
import { isAuthorizedSelector } from '../../../../selectors/user';

const ChildrenListItem: React.FC<Item> = ({ href, icon, name, groups }) => {
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, groups));
  if (!isAuthorized) return null;

  return (
    <ListItemButton
      disableRipple
      disabled={!isAuthorized}
      component={NavLink}
      to={href || '#'}
      className={styles.listItem}>
      <ListItemIcon className={styles.listIcon}>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItemButton>
  );
};

const SidebarItem: React.FC<MenuItem> = ({ item, children }) => {
  const location = useLocation();
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, item.groups));
  const [open, setOpen] = useState(false);

  // It checks to find a match between the current path and the
  // href attribute of any of the children. If it finds a match,
  // then it opens the collapse section. It only runs on the
  // very first time loading of the component.
  useEffect(() => {
    if (children) {
      const shouldCollapse = children.some((child) => {
        if (!child?.href) {
          return false;
        }
        return location.pathname.startsWith(child.href);
      });

      if (shouldCollapse) {
        setOpen(true);
      }
    }
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  if (!isAuthorized) return null;

  const listItemProps = children
    ? { component: 'div' }
    : {
        component: NavLink,
        to: item.href || '#',
        activeClassName: 'Mui-selected',
      };

  const mainItem = (
    <ListItemButton disableGutters disableRipple className={styles.mainItem} onClick={handleClick} {...listItemProps}>
      <ListItemIcon className={styles.listIcon}>{item.icon}</ListItemIcon>
      <ListItemText primary={item.name} />
      {children ? open ? <ExpandMoreIcon /> : <ChevronRightIcon /> : null}
    </ListItemButton>
  );

  const childrenList = children ? (
    <Collapse in={open} timeout='auto' unmountOnExit>
      <List dense={true} disablePadding>
        {children.map((child) => (
          <ChildrenListItem key={nanoid()} {...child} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <List dense={true} className={styles.itemGroup}>
      {mainItem}
      {childrenList}
    </List>
  );
};

export default SidebarItem;
