import { Check, Close } from '@mui/icons-material';
import { Button, Chip } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './Badges.module.scss';

type Props = {
  status: string | undefined;
  onClick?: (ev?: React.MouseEvent) => void;
  showIcon?: boolean;
  size?: 'small' | 'medium';
};

const STATUS_ICONS: { [param: string]: React.ReactElement } = {
  approve: <Check />,
  reject: <Close />,
};

const STATUS_MAPPING: {
  [param: string]: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  approve: 'success',
  approved: 'success',
  accepted: 'success',
  pending: 'info',
  reject: 'error',
  rejected: 'error',
  refused: 'error',
  requested: 'info',
  shipped: 'warning',
};

const StatusBadge: React.FC<Props> = ({ status, onClick, showIcon, size }) => {
  const intl = useIntl();

  if (!status) return null;

  const statusMessageId = `status_${status.replace(' ', '_')}`;
  const commonMessageId = `common_${status.replace(' ', '_')}`;
  let label: any = status;
  if (intl.messages[statusMessageId]) {
    label = <FormattedMessage id={statusMessageId} defaultMessage={status} />;
  } else if (intl.messages[commonMessageId]) {
    label = <FormattedMessage id={commonMessageId} defaultMessage={status} />;
  }

  return onClick ? (
    <Button
      startIcon={showIcon ? STATUS_ICONS[status] : undefined}
      size={size ?? (showIcon ? 'medium' : 'small')}
      color={STATUS_MAPPING?.[status.toLowerCase()] || 'inherit'}
      variant='outlined'
      sx={{ fontSize: '0.7rem' }}
      onClick={onClick}>
      {label}
    </Button>
  ) : (
    <Chip
      icon={showIcon ? STATUS_ICONS[status] : undefined}
      size={size ?? (showIcon ? 'medium' : 'small')}
      color={STATUS_MAPPING?.[status.toLowerCase()] || 'default'}
      className={styles.chip}
      label={label}
    />
  );
};

export default StatusBadge;
