const enMessages = {
  auth_buttons_login: 'Log In',
  auth_buttons_passwordReset: 'Reset Password',
  auth_buttons_setPassword: 'Set password',
  auth_labels_email: 'E-mail address',
  auth_labels_newPassword: 'New Password',
  auth_labels_password: 'Password',
  auth_labels_repeatPassword: 'Repeat Password',
  auth_login_title: 'Login',
  auth_passwordReset_successMessage:
    'If your email address is stored in our system, you will receive an email from us to reset your password. Please click on the link provided to reset your password.',
  auth_passwordReset_title: 'Set New Password',
  auth_unauthorized_message:
    'You are not authorized to view this page. If you think this is a mistake, please contact your administrator.',
  auth_unauthorized_title: 'Error',
  common_actions: 'Actions',
  common_add: 'Add',
  common_all: 'All',
  common_approve: 'Approve',
  common_back: 'Back',
  common_cancel: 'Cancel',
  common_clear: 'Clear',
  common_close: 'Close',
  common_days: '{count, plural, one {# day} other {# days}}',
  common_decline: 'Decline',
  common_delete: 'Delete',
  common_details: 'Details',
  common_disable: 'Disable',
  common_edit: 'Edit',
  common_enable: 'Enable',
  common_filters: 'Filters',
  common_months: '{count, plural, one {# month} other {# months}}',
  common_next: 'Next',
  common_no: 'No',
  common_ok: 'Ok',
  common_optional: 'optional',
  common_refresh: 'Refresh',
  common_reject: 'Reject',
  common_remove: 'Remove',
  common_save: 'Save',
  common_search: 'Search',
  common_set: 'Set',
  common_show: 'Show',
  common_status: 'Status',
  common_submit: 'Submit',
  common_weeks: '{count, plural, one {# week} other {# weeks}}',
  common_years: '{count, plural, one {# year} other {# years}}',
  common_yes: 'Yes',
  dataGrid_errorOverlayDefaultLabel: 'An error occurred.',
  dataGrid_noResultsOverlayLabel: 'No results found.',
  dataGrid_noRowsLabel: 'No Rows',
  forms_dateFilter_endDate: 'End Date',
  forms_dateFilter_startDate: 'Start Date',
  forms_fileUpload_label: 'Upload Files',
  forms_fileUpload_noFiles: 'No files selected',
  menu_orders: 'Orders',
  menu_orders_description: 'List available orders',
  menu_users: 'Users',
  menu_users_description: 'List available users',
  orders_details_cardTitles_acceptance: 'Acceptance',
  orders_details_cardTitles_item: 'Item',
  orders_details_cardTitles_other: 'Other',
  orders_details_cardTitles_shipment: 'Shipment',
  orders_details_title: 'Order {reference}',
  orders_details_updateTracking: 'Update Tracking',
  orders_labels_acceptanceStatus: 'Acceptance Status',
  orders_labels_brand: 'Brand',
  orders_labels_customerPrice: 'List Price',
  orders_labels_firstAcceptanceDate: 'Acceptance Status first changed at',
  orders_labels_firstAcceptanceUser: 'Acceptance Status first changed by',
  orders_labels_image: 'Image',
  orders_labels_model: 'Model',
  orders_labels_orderDate: 'Order Date',
  orders_labels_orderNo: 'Order No.',
  orders_labels_orderStatus: 'Order Status',
  orders_labels_payoutAmount: 'Payout Amount',
  orders_labels_reference: 'Reference',
  orders_labels_shipmentStatus: 'Shipment Status',
  orders_labels_tracking: 'Tracking',
  orders_labels_vendorCarrier: 'Carrier',
  orders_labels_vendorComment: 'Comment',
  orders_labels_vendorItemId: 'Item ID',
  orders_labels_vendorName: 'Vendor Name',
  orders_labels_vendorOrderTrackingDate: 'Tracking last updated at',
  orders_labels_vendorOrderTrackingUser: 'Tracking last updated by',
  orders_labels_vendorPrice: 'Vendor Price',
  orders_labels_vendors: 'Vendors',
  orders_labels_vendorTrackingNo: 'Tracking No.',
  orders_tabs_overview: 'Overview',
  orders_tabs_pendingOrders: 'Pending Orders',
  orders_tabs_shipping: 'Shipping',
  orders_updateAcceptanceStatusModal_adminWarningText:
    'This action is usually performed by the vendor. Please make sure you are authorized to perform this action.',
  orders_updateAcceptanceStatusModal_title:
    '{action, select, approve {Approve} reject {Reject} other { }} Order "{reference}"?',
  orders_updateModal_title: 'Update selected {count, plural, one {order} other {orders}}?',
  status_accepted: 'accepted',
  status_approved: 'approved',
  status_pending: 'pending',
  status_refused: 'refused',
  status_rejected: 'rejected',
  status_requested: 'requested',
  status_shipped: 'shipped',
  successMessages_order_approveOrder: 'Successfully approved order {reference}',
  successMessages_order_rejectOrder: 'Successfully rejected order {reference}',
  successMessages_order_updateOrder: 'Successfully updated order {reference}',
  successMessages_order_updateOrders: 'Successfully updated {count, plural, one {order} other {orders}} {references}',
  successMessages_user_createUser: 'Successfully created user {username}',
  successMessages_user_deleteUser: 'Successfully deleted user {username}',
  successMessages_user_login: 'Successfully logged in',
  successMessages_user_setNewPassword: 'Successfully set new password',
  successMessages_user_updateUser: 'Successfully updated user {username}',
  users_actions_create: 'Create new User',
  users_createModal_description: 'To add a new User, please fill out the fields below.',
  users_createModal_title: 'New User',
  users_deleteModal_title: 'Delete User "{email}"?',
  users_deleteModal_warningText: 'This action cannot be undone.',
  users_labels_email: 'E-mail address',
  users_labels_firstName: 'First Name',
  users_labels_lastName: 'Last Name',
  users_labels_role: 'Role',
  users_labels_vendors: 'Vendors',
  users_updateModal_description:
    'To update this user, please change values as needed below. Please be aware that the email cannot be changed.',
  users_updateModal_title: 'Update User "{email}"',
};

export default enMessages;
