import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

type Props = ButtonProps & {
  isLoading: boolean;
};
const LoadingButton: React.FC<Props> = ({ isLoading, children, ...props }) => (
  <Button {...props}>
    {children}
    {isLoading && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Button>
);

export default LoadingButton;
